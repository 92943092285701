import { Chip, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  Paper,
  Card,
  Box,
  Typography,
  MenuItem,
  Breadcrumbs,
  Avatar,
  TablePagination,
  CircularProgress,
  IconButton,
  Collapse,
  Grid2,
  TextField,
  Button
} from "@mui/material";

import { toast } from "react-toastify";
import TemplateView from "../pages/NewCampaign/Components/TemplateView";
import CampaignTemplateModal from "./CampaignTemplateModal/CampaignTemplateModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { CampaignListEditSlice } from "../redux/stepperSlice/stepper.slice";
import { fetchTeamsDetailsSlice } from "../redux/teamSlice/team.slice";


const styles = {
  container: (isSmallScreen) => ({
    padding: 2,
    display: "flex",
    justifyContent: "",
    flexWrap: isSmallScreen ? "wrap" : "nowrap",
    flexDirection: isSmallScreen ? "column" : "row",
  }),
  boxWidth: { width: "27%" },
  labelText: { color: "#455967", fontWeight: "600", fontSize: "16px" },
  valueText: { fontSize: "14px", fontWeight: "400", color: "#455967" },
  chip: {
    borderRadius: "18px",
    background: "#FF8D5D",
    color: "#fff",
    "& .MuiChip-deleteIcon": { color: "white", fontSize: "16px" },
    "& .MuiChip-deleteIcon:hover": {
      backgroundColor: "transparent",
      color: "white",
    },
    margin: "2px",
  },
};
function formatDate(dateString) {
  if (!dateString) {
    return false
  }
  const date = new Date(dateString);

  // Options for formatting
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",

  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

const formatDateNew = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};


const campaignTypeOptions = {
  LEADGENERATION: "Lead Generation",
  BRANDAWARENESS: "Brand Awareness",
  WEBSITETRAFFIC: "Website Traffic",
  SALES: "Sales",
};


const ResponsiveTableView = ({ openRowIndex, index, viewCampaignDetails, handleExecute, page, rowsPerPage, t }) => {

  useEffect(() => {
    setEditedData({
      campaignName: viewCampaignDetails?.campaignName,
      description: viewCampaignDetails?.description,
      budget: viewCampaignDetails?.budget,
      campaignType: viewCampaignDetails?.campaignType,
      startDate: dayjs(viewCampaignDetails?.startDate),
      endDate: dayjs(viewCampaignDetails?.endDate),

    })
  }, [viewCampaignDetails])

  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const userNotificationData = viewCampaignDetails?.userNotification;
  const [openTemplate, setOpenTemplate] = useState(false);
  const dispatch = useDispatch();

  const handleTemplateView = () => {
    setOpenTemplate(false);

  }

  const [isEditing, setIsEditing] = useState(false); // Edit mode state
  const [editedData, setEditedData] = useState({
    campaignName: viewCampaignDetails?.campaignName || "",
    description: viewCampaignDetails?.description || "",
    budget: viewCampaignDetails?.budget || "",
    startDate: viewCampaignDetails?.startDate
      ? dayjs(viewCampaignDetails.startDate)
      : null,
    endDate: viewCampaignDetails?.endDate
      ? dayjs(viewCampaignDetails.endDate)
      : null,
  });

  const handleFieldChange = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const saveChanges = async () => {
    // handleSave(editedData); // Pass updated data to save handler
    //   handleSave({
    //     ...editedData,
    //     startDate: editedData.startDate?.toISOString() || null,
    //     endDate: editedData.endDate?.toISOString() || null,
    //   }); // Pass updated data to save handler
    //   setIsEditing(false); // Exit edit mode
    // };

    const campaignId = viewCampaignDetails?.campaignId;

    const payload = {
      ...viewCampaignDetails,
      campaignName: editedData.campaignName,
      description: editedData.description,
      startDate: editedData.startDate,
      endDate: editedData.endDate,
      budget: editedData.totalBudget,
      campaignType: editedData?.campaignType,
    };

    const result = await dispatch(CampaignListEditSlice({ data: payload, campaignId })).unwrap();

    if (result) {
      dispatch(fetchTeamsDetailsSlice({ page, rowsPerPage }));
      setIsEditing(false);
    }
    else{
      setIsEditing(false);
    }
  };



  return (
    openRowIndex === index && (
      <>
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={11}
            sx={{ backgroundColor: "#D5F3F666" }}
          >
            <Collapse in={openRowIndex === index} timeout="auto" unmountOnExit>


              <Box sx={styles.container(isSmallScreen)}>
                {/* Campaign Information */}
                <Box sx={styles.boxWidth}>
                  {/* <Typography sx={styles.labelText}>
                    Campaign Name:{" "}
                    <span style={styles.valueText}>
                      {viewCampaignDetails?.campaignName ? viewCampaignDetails?.campaignName : 'Data not persent'}
                    </span>
                  </Typography> */}
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: 'center' }}>
                      <Typography sx={styles.labelText}>{t('campaignList.campaignName')}:</Typography>
                      {isEditing ? (
                        <TextField
                          value={editedData.campaignName}
                          onChange={(e) => handleFieldChange("campaignName", e.target.value)}
                          size="small"
                          sx={{ width: '200px' }}
                          fullWidth
                          label="Campaign Name"
                          placeholder="Enter Campaign Name"
                        />
                      ) : (
                        <Typography>{viewCampaignDetails?.campaignName || "Data not present"}</Typography>
                      )}
                    </Box>
                  </Box>

                </Box>
                <Box sx={styles.boxWidth}>
                  {/* <Typography sx={styles.labelText}>
                    Website Url:{" "}
                    <span style={styles.valueText}>
                      {viewCampaignDetails?.description ? viewCampaignDetails?.description : 'Data not persent'}

                    </span>
                  </Typography> */}

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: 'center' }}>
                    <Typography sx={styles.labelText}> {t('campaignList.websiteUrl')}:</Typography>
                    {isEditing ? (
                      <TextField
                        value={editedData.description}
                        onChange={(e) => handleFieldChange("description", e.target.value)}
                        size="small"
                        sx={{ width: '200px' }}
                        fullWidth
                        label="Campaign Name"
                        placeholder="Enter Campaign Name"
                      />
                    ) : (
                      <Typography>{viewCampaignDetails?.description || "Data not present"}</Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={styles.boxWidth}>
                  <Typography sx={styles.labelText}>
                  {t('campaignList.channel')}:{" "}
                    <span
                      style={
                        {
                          ...styles.valueText,
                          cursor: viewCampaignDetails?.channel ? 'pointer' : 'default',
                          textDecoration: viewCampaignDetails?.channel ? 'underline' : 'none',
                        }}
                      onClick={() => {
                        if (viewCampaignDetails?.channel) {
                          setOpenTemplate(true)
                        }
                      }
                      }
                    >
                      {viewCampaignDetails?.channel ? viewCampaignDetails?.channel === 'WHATSAPP' ? 'Whatsapp' : viewCampaignDetails?.channel : 'Data not persent'}
                    </span>
                  </Typography>
                </Box>
              </Box>

              <Box sx={styles.container(isSmallScreen)}>
                {/* Campaign Information */}

                <Box sx={styles.boxWidth}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: 'center' }}>
                    <Typography sx={styles.labelText}>{t('campaignList.campaignType')}:</Typography>
                    {isEditing ? (
                      <Select
                        value={editedData.campaignType || ""} // Ensure no undefined value
                        onChange={(e) => handleFieldChange("campaignType", e.target.value)}
                        size="small"
                        sx={{ width: '200px' }}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select Campaign Type
                        </MenuItem>
                        {Object.entries(campaignTypeOptions).map(([key, label]) => (
                          <MenuItem key={key} value={key}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Typography>
                        {campaignTypeOptions[viewCampaignDetails?.campaignType] || "Data not present"}
                      </Typography>
                    )}
                  </Box>

                </Box>

                <Box sx={styles.boxWidth}>
                  {/* <Typography sx={styles.labelText}>
                    Start Date:{" "}
                    <span style={styles.valueText}>
                      {formatDate(viewCampaignDetails?.startDate) ? formatDate(viewCampaignDetails?.startDate) : 'Data not persent'}
                    </span>
                  </Typography> */}

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
                    <Typography sx={styles.labelText}>{t('campaignList.startDate')}:</Typography>
                    {isEditing ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={editedData.startDate ? dayjs(editedData.startDate) : null} // Ensure compatibility with dayjs
                          onChange={(date) =>
                            handleFieldChange("startDate", date ? dayjs(date).toISOString() : null)
                          }
                          format="DD/MM/YYYY"
                          closeOnSelect
                          minDate={dayjs()} // Prevent past dates
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              sx={{ width: "200px" }}
                              label="Start Date"
                              error={!editedData.startDate}
                              helperText={!editedData.startDate ? "Start Date is required" : ""}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    ) : (
                      <Typography sx={styles.valueText}>
                        {viewCampaignDetails?.startDate
                          ? formatDateNew(viewCampaignDetails.startDate)
                          : "Data not present"}
                      </Typography>
                    )}
                  </Box>

                </Box>
                {/* <Box sx={styles.boxWidth}>
                  <Typography sx={styles.labelText}>
                    End Date:{" "}
                    <span style={styles.valueText}>
                      {formatDate(viewCampaignDetails?.endDate) ? formatDate(viewCampaignDetails?.endDate) : 'Data not persent'}

                    </span>
                  </Typography>
                </Box> */}



                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
                  <Typography sx={styles.labelText}>{t('campaignList.endDate')}:</Typography>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        value={editedData.endDate ? dayjs(editedData.endDate) : null} // Ensure compatibility with dayjs
                        onChange={(date) =>
                          handleFieldChange("endDate", date ? dayjs(date).toISOString() : null)
                        }
                        format="DD/MM/YYYY"
                        closeOnSelect
                        minDate={dayjs()} // Prevent past dates
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            sx={{ width: "200px" }}
                            label="Start Date"
                            error={!editedData.endDate}
                            helperText={!editedData.endDate ? "Start Date is required" : ""}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Typography sx={styles.valueText}>
                      {viewCampaignDetails?.endDate
                        ? formatDateNew(viewCampaignDetails.endDate)
                        : "Data not present"}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={styles.container(isSmallScreen)}>
                <Box sx={styles.boxWidth}>
                  <Typography sx={styles.labelText}>
                  {t('campaignList.totalBudget')}:{" "}
                    <span style={styles.valueText}>
                      {viewCampaignDetails?.budget ? viewCampaignDetails?.budget : 'Data not persent'}
                    </span>
                  </Typography>
                </Box>

                {
                  viewCampaignDetails?.attributes?.map((item, index) => {
                    return (
                      <Box key={index} style={{ marginBottom: "8px" }} sx={styles.boxWidth} >
                        <Typography sx={styles.labelText}>
                          {item.name}:
                        </Typography>

                        {item?.possibleValues?.map((val, idx) => (
                          <Chip
                            key={idx}
                            label={val}
                            size="small"
                            style={{ margin: "2px" }}
                            sx={{
                              borderRadius: "18px",
                              marginBottom: "4px",
                              background: "#445A68E5",
                              color: "#fff",
                              "& .MuiChip-deleteIcon": {
                                color: "white",
                                fontSize: "16px",
                              },
                              "& .MuiChip-deleteIcon:hover": {
                                backgroundColor: "transparent",
                                color: "white",
                              },
                            }}
                          />
                        ))}
                      </Box>
                    )
                  })
                }

              </Box>


              <Box sx={styles.container(isSmallScreen)}>

                {
                  viewCampaignDetails?.budgetSchedule?.campaignScheduleList?.map((item, index) => {
                    return (
                      <Box sx={styles.boxWidth}>

                        <Typography sx={{ ...styles.labelText, paddingBottom: '4px' }}>{t('campaignList.noOfDays')}{" "}{item?.noOfDays}</Typography>

                        <Typography sx={{ ...styles.labelText, paddingY: '2px' }}>
                        {t('campaignList.budget')}:-{" "}
                          <span style={styles.valueText}>{item?.budget}</span>
                        </Typography>

                        <Typography sx={{ ...styles.labelText, paddingY: '2px' }}>
                        {t('campaignList.estimatedImpressions')}:-{" "}
                          <span style={styles.valueText}>{item?.estimatedImpressions}</span>
                        </Typography>
                      </Box>
                    )
                  })
                }
              </Box>

              {
                viewCampaignDetails?.userNotification !== null && (
                  <>
                    <Box sx={styles.container(isSmallScreen)}>

                      <Typography sx={styles.labelText}>
                      {t('campaignList.campaignName')}:{" "}
                        <span style={styles.valueText}>
                          {userNotificationData?.message}
                        </span>
                      </Typography>
                    </Box>

                    <Box sx={styles.container(isSmallScreen)}>
                      <Chip
                        label={
                          userNotificationData?.status === "DRAFT" ? "Execute" : "Executed"
                        }
                        style={{
                          backgroundColor:
                            userNotificationData?.status === "DRAFT"
                              ? "transparent"
                              : "#1976d2", // Primary color for Executed
                          color: userNotificationData?.status === "DRAFT" ? "inherit" : "#fff", // White text for Executed
                          borderColor:
                            userNotificationData?.status === "DRAFT" ? "#1976d2" : "#1976d2", // Primary border color for both
                        }}
                        variant="outlined"
                        onClick={(event) => {
                          event.stopPropagation();

                          if (userNotificationData?.status === "APPROVED") {
                            // If the status is already Executed, show a toast
                            toast.info("Already Executed");
                          } else {
                            // If the status is not Executed, proceed with the approve action
                            handleExecute();
                          }
                        }}
                      />
                    </Box>


                  </>
                )
              }

              {viewCampaignDetails?.status === 'DRAFT' && (
                <Box sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}>
                  {isEditing ? (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setEditedData({
                            campaignName: viewCampaignDetails?.campaignName || "",
                            description: viewCampaignDetails?.description || "",
                            budget: viewCampaignDetails?.budget || "",
                          }); // Reset values to the previous state
                          setIsEditing(false);
                        }}
                        sx={{ marginRight: 1 }}
                      >
                        {t('cancel')}
                      </Button>
                      <Button variant="contained" color="primary" onClick={saveChanges}>
                      {t('save')}
                      </Button>
                    </>
                  ) : (
                    <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
                      {t('campaignList.edit')}
                    </Button>
                  )}
                </Box>
              )}

            </Collapse>
          </TableCell>
        </TableRow>
        <TemplateView open={openTemplate} handleClose={handleTemplateView} tempType={viewCampaignDetails?.channel} t={t} />
      </>
    )
  );
};


export default ResponsiveTableView