import React, { useEffect, useState } from 'react'
import { AppBar, Box, Menu, MenuItem, Toolbar ,  Switch, Typography} from '@mui/material';
import LOGO from '../../assets/Vector.png';
import CustomBreadcrumb from './CustomBreadcrumb';
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/material/styles";
import LanguageIcon from '@mui/icons-material/Language';




const MainHeader = ({ rightOpen}) => {
 
    const { i18n, t } = useTranslation();
    const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
   
    const handleLanguageMenuOpen = (event) => {
        setLanguageMenuAnchor(event.currentTarget)
    }

    const handleLanguageMenuClose = (event) => {
        setLanguageMenuAnchor(null)
    }

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        handleLanguageMenuClose();
    }




    

    return (
        <AppBar
            position="relative"
            sx={{
                backgroundColor: '#fff',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                zIndex: 1201,
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
            }}
        >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>

                {/* Breadcrumb */}

                <CustomBreadcrumb />
          
                {/* Logo */}
                <Box sx={{ display: "flex", alignItems: "center", marginRight: rightOpen ? '' : '34px', gap: '20px' }}>
                    <img
                        src={LOGO}
                        alt="AIonOS Logo"
                        style={{ height: '36px' }}
                    />

                    <LanguageIcon onClick={handleLanguageMenuOpen} sx={{ fill: '#13BECF', fontSize: '1.75rem', cursor: 'pointer' }} />
                </Box>
                <Menu
                    anchorEl={languageMenuAnchor}
                    open={Boolean(languageMenuAnchor)}
                    onClose={handleLanguageMenuClose}
                >
                    <MenuItem onClick={() => changeLanguage('en')} selected={i18n.language === 'en'}>English</MenuItem>
                    <MenuItem onClick={() => changeLanguage('id')} selected={i18n.language === 'id'}>Indonesian</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}

export default MainHeader