import axiosInstance from "../../components/utils/axiosInstance";
import { toast } from "react-toastify";


export const adminSignUpThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/profile/master/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        toast.success('Profile Created Successfully.')

        return response.data

    } catch (error) {

        return toast.error(error.response.data);
    }
}

export const fetchDataConsumerAndProviderThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/profile/user/search/group?group=${data?.userType}`, {
            params: {
                page: data?.page,
                size: data?.rowsPerPage,
            },
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const fetchAttributesListThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/attributes/attributes/list`, {
            params: {
                page: data?.page,
                size: data?.rowsPerPage,
            },
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const fetchAttributesCategoryListThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/attributes/attributes/name`, {
            params: {
                categoryId: data,
            },
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const fetchAttributesCategoryThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/attributes/attributes/category`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const fetchMetaTypeThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/attributes/attributes/metaType`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const fetchAttributeTypeThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/attributes/attributes/type`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const createAttributeThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/attributes/attributes`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log("resp attribute post", response);

        return response

    } catch (error) {
        console.log("errorAttribute---", error)
        if (error?.status === 409) {
            return toast.error("Attribute with the same name already exists!");
        }
        return toast.error(error?.message);
    }
}

export const fetchAttributesMappingThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/attributes/provider-attributes`, {
            params: {
                page: data?.page,
                size: data?.rowsPerPage,
            },
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const createAttributeMapping = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/attributes/provider-attributes`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data

    } catch (error) {

        return toast.error(error.response.data.message);
    }
}

export const fetchProfileThunk = async (data) => {
    try {
        const token = localStorage.getItem("idToken");

        const response = await axiosInstance.get(`/api/profile/master/get`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};


export const updateConsumerThunk = async (data) => {

    try {

        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.put(`/api/profile/master/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        toast.success("Profile Updated Successfully")

        return response.data

    } catch (error) {


        if (error.response.status === 500) {
            return toast.error(error.response.data.error)
        } else {
            return toast.error(error.response.data)

        }
    }
}

export const fetchRegisterUsersThunk = async (data) => {
    try {
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            toast.error("Unauthorized access. Please log in.");
        }

        const response = await axiosInstance.get(`api/profile/user`, {
            params: {
                page: data?.page,
                size: data?.rowsPerPage,
            },
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
};

export const resendEmailThunk = async (data) => {

    try {


        const response = await axiosInstance.post(`/api/profile/user/email/resend-verification-email?email=${data}`
        );


        toast.success(response.data)

        return response.data

    } catch (error) {
        console.log('error-------------------------------', error)
        return toast.error(error.response.data.message);
    }
}

export const mobileOtpSendThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/profile/master/sendOtp?mobileNumber=${data}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data

    } catch (error) {

        return toast.error(error.response.data);
    }
}

export const verifyOtpThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/profile/master/verifyOtp`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });


        console.log("responseVerify", response)
        return response

    } catch (error) {

        return toast.error(error.response.data);
    }
}

export const mobileOtpReSendThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/profile/master/resendOtp?mobileNumber=${data?.mobileNumber}&txnId=${data?.txnId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data

    } catch (error) {

        return toast.error(error.response.data);
    }
}

export const SearchAttributeListThunk = async ({ page, value }) => {
    try {
        const token = localStorage.getItem("idToken");
        if (!token) {
            toast.error("Unauthorized access. Please log in.");
            return;
        }

        const params = {
            name: value || "",
            page: page?.page || 0,
            size: page?.rowsPerPage || 10,
        };

        const response = await axiosInstance.get(`api/attributes/attributes/search`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        toast.error(error?.response?.data?.message || "An error occurred.");
        console.log("Error:", error?.response?.data?.message || error.message);
    }
};

export const fetchCountryRegionList = async () => {

    try {
        const token = localStorage.getItem("idToken");
        if (!token) {
            toast.error("Unauthorized access. Please log in.");
            return;
        }

        const response = await axiosInstance.get(`api/profile/user/country-regions`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;

    } catch (error) {
        toast.error(error?.response?.data?.message || "An error occurred.");
        console.log("Error:", error?.response?.data?.message || error.message);
    }
}

export const fetchBusinessCategoryList = async () => {

    try {
        const token = localStorage.getItem("idToken");
        if (!token) {
            toast.error("Unauthorized access. Please log in.");
            return;
        }

        const response = await axiosInstance.get(`api/profile/user/business-categories`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;

    } catch (error) {
        toast.error(error?.response?.data?.message || "An error occurred.");
        console.log("Error:", error?.response?.data?.message || error.message);
    }
}

export const fetchAudienceLanguageList = async () => {

    try {
        const token = localStorage.getItem("idToken");
        if (!token) {
            toast.error("Unauthorized access. Please log in.");
            return;
        }

        const response = await axiosInstance.get(`api/profile/user/audience-languages`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;

    } catch (error) {
        toast.error(error?.response?.data?.message || "An error occurred.");
        console.log("Error:", error?.response?.data?.message || error.message);
    }
}

