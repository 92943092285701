import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import TemplateViewScreen from "./TemplateViewScreen";


const TemplateView = ({ open, handleClose, tempType, templateData, t }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const characterLimit = 150;
    const whatsAppData = templateData?.components;
    const renderText = () => {
        const text = whatsAppData[1].text || ''; // Safely access textBody
        // If expanded, return the full text
        if (isExpanded) {
            return text;
        }
        // If collapsed, return truncated text
        return whatsAppData[1].text.length > characterLimit ? `${text.substring(0, characterLimit)}...` : text;
    };

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // useEffect(() => {
    //     if (!templateData?.textBody || templateData?.textBody.trim() === "") {
    //         setIsExpanded(false);
    //     }
    // }, [templateData?.textBody]);

    // console.log("dataToShow[2]?.buttons",);
    // console.log('selectedValue', selectedValue)

    return (
        <CustomModal
            open={open}
            onClose={() => {
                handleClose();
            }}
            heading={t('campaignList.templatePreview')}
            customWidth={"710px"}
        >

            {/* WhatsApp UI */}

            <TemplateViewScreen
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                handleToggleExpand={handleToggleExpand}
                tempType={tempType}
                templateData={templateData}
                renderText={renderText}
                characterLimit={characterLimit}
                centered={true}
                t={t}
            />
        </CustomModal>
    )
}

export default TemplateView