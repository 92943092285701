import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Breadcrumbs,
    Card,
    TextField,
    Typography,
    Chip,
    Menu,
    MenuItem,
    IconButton,
    Button,
    Autocomplete,
    TextArea,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid2";
import { Formik, Field, Form, FieldArray } from "formik";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import WhatsAppImage from "../../assets/watsapp Demo Screen 1.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WhatsAppDemoImage from "../../assets/Vector_1.svg";
import WhatsAppDemoVideo from "../../assets/DemoVideo.jpg";
import WhatsAppDemoPDF from "../../assets/WhatsAppDemoPDF.jpg";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import ChatInterface from "./ChatUi";
import { UploadCreateTemplateHeader } from "../../redux/teamSlice/team.thunk";
import { toast } from "react-toastify";
import axios from "axios";
import {
    createTemplateSlice,
    fetchContentListMediaSlice,
} from "../../redux/teamSlice/team.slice";
import CreateSmsCommunication from "./CreateSmsCommunication";
import axiosInstance from "../../components/utils/axiosInstance";
import * as Yup from "yup";
import MediaModal from "./MediaModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const language = [
    {
        label: "English",
        value: "en_US",
    },
];

const statusList = [
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Inactive",
        value: "inactive",
    },
];

const categoryList = [
    {
        label: "Marketing",
        value: "marketing",
    },
    {
        label: "One Time Passwords",
        value: "otp",
    },
    {
        label: "Transactional",
        value: "transactional",
    },
];

const actionTypeList = ["Link", "Call", "Message"];
const urlTypeList = [{ value: "URl", label: "URl" }];
const initialValues = {
    templateName: "",
    templateLanguage: "",
    templateStatus: "",
    templateCategory: "",
    templateHeader: "",
    textBody: "",
    footerText: "",
    header: "",
    buttonRows: [
        {
            id: Date.now(),
            buttonText: "",
            actionType: "",
            websiteUrl: "",
            urlType: "",
        },
    ],
};

const CreateTemplateFinal = ({ t }) => {
    const classes = useStyles();
    const [formValues, setFormValues] = useState(initialValues);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [selectedValue2, setSelectedValue2] = useState("");
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [headerImage, setHeaderImage] = useState("");
    const [selectedFilePreview, setSelectedFilePreview] = useState(null);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const textFieldRef = useRef(null);
    const navigate = useNavigate();
    const [text, setText] = useState("");
    const [nextVariableIndex, setNextVariableIndex] = useState(1);

    const validationSchema = Yup.object().shape({
        templateName: Yup.string()
            .required(t("validate.templateNameReq"))
            .matches(
                /^[a-z0-9!@#$%^&*()_+=\-{}\[\]:;"'<>,.?\\/|~`]+$/,
                t("validate.tempNameWithoutSpace")
            ),
        templateCategory: Yup.string().required(t("validate.templateCategoryReq")),
        templateLanguage: Yup.string().required(t("validate.templateLangReq")),
        templateStatus: Yup.string().required(t("validate.templateStatusReq")),
        textBody: Yup.string().required(t("validate.textBodyReq")),
        footerText: Yup.string().required(t("validate.footerTextReq")),
    });

    const handleFileChange = async (event) => {
        if (selectedValue === "") {
            toast.error("Please Select Respective Header First!");
            return;
        }

        const file = event.target.files[0];

        if (!file) {
            toast.error("No file selected!");
            return;
        }

        if (selectedValue === "image" || selectedValue === "video") {
            setSelectedFilePreview(URL.createObjectURL(file));
        } else if (selectedValue === "document") {
            setSelectedFilePreview(file.name);
        }

        setSelectedFile(file);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", selectedValue); // 'image' or 'video' or 'document'
        formData.append("description", "testing");
        formData.append(
            "title",
            `testing.${selectedValue === "video"
                ? "mp4"
                : selectedValue === "document"
                    ? "pdf"
                    : "mp4"
            }`
        );

        try {
            const token = localStorage.getItem("idToken");
            if (!token) {
                toast.error("Unauthorized access.");
                return;
            }

            const response = await axiosInstance.post(`api/cm/minio/file`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            toast.success("File uploaded successfully!");
            console.log("API Response:", response.data);
            setHeaderImage(response.data);
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error("Failed to upload file. Please try again.");
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setSelectedFilePreview(null);
        setHeaderImage("");
    };

    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        setAnchorEl(null);
        setSelectedValue(value);
    };

    const handleButtonTypeClick = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const handleButtonTypeClose = (value) => {
        setAnchorE2(null);
        if (value) {
            setSelectedValue2(value);
        }
    };

    // Add a new button row
    const handleAddButtonRow = (setFieldValue, values) => {
        const newButtonRow = {
            id: Date.now(),
            buttonText: "",
            actionType: "",
            websiteUrl: "",
            urlType: "",
        };
        setFieldValue("buttonRows", [...values.buttonRows, newButtonRow]);
    };

    // Delete a button row
    const handleDeleteButtonRow = (index, setFieldValue, values) => {
        const updatedButtonRows = values.buttonRows.filter((_, i) => i !== index);
        const updatedValues = { ...values, buttonRows: updatedButtonRows };
        setFormValues(updatedValues);
        setFieldValue("buttonRows", updatedButtonRows);
    };

    const handleFormChange = (e, fieldName, value) => {
        setFormValues(value);
    };

    const handleSubmit = async (values, resetForm) => {
        const buttons = values.buttonRows
            .filter((button) => button.buttonText && button.buttonText.trim() !== "") // Exclude buttons with empty buttonText
            .map((button) => ({
                type: button.urlType.value,
                text: button.buttonText,
                url: button.websiteUrl,
            }));

        // var data = {
        //     "name": values.templateName,
        //     "language": values.templateLanguage,
        //     "category": values.templateCategory,
        //     "components": [
        //         {
        //             "type": "HEADER",
        //             "format": selectedValue?.toUpperCase(),
        //             "text": selectedValue === 'text' ? values?.header : null,
        //             "media": selectedValue !== 'text' ? selectedFilePreview?.content : null,

        //         },
        //         ...(values?.footerText ? [{
        //             "type": "FOOTER",
        //             "text": values.footerText
        //         }] : []),
        //         ...(values?.textBody ? [{
        //             "type": "BODY",
        //             "text": values?.textBody
        //         }] : []),
        //         ...(buttons && buttons.length > 0 ? [{
        //             "type": "BUTTONS",
        //             "buttons": buttons,
        //         }] : [])
        //     ]
        // }

        const components = [
            // Conditionally add HEADER only if templateCategory is not AUTHENTICATION
            ...(values.templateCategory !== "AUTHENTICATION"
                ? [
                    {
                        type: "HEADER",
                        format: selectedValue?.toUpperCase(),
                        text: selectedValue === "text" ? values?.header : null,
                        media:
                            selectedValue !== "text" ? headerImage?.url : null,
                    },
                ]
                : []),
            ...(values?.footerText
                ? [
                    {
                        type: "FOOTER",
                        text: values.footerText,
                    },
                ]
                : []),
            ...(values?.textBody
                ? [
                    {
                        type: "BODY",
                        text: values?.textBody,
                    },
                ]
                : []),
            ...(buttons && buttons.length > 0
                ? [
                    {
                        type: "BUTTONS",
                        buttons: buttons,
                    },
                ]
                : []),
        ];

        const data = {
            name: values.templateName,
            language: values.templateLanguage,
            category: values.templateCategory,
            components: components,
        };

        // const result = await dispatch(createTemplateSlice(data));

        // if (result?.error?.message !== "400") {
        //     resetForm();
        //     setFormValues(initialValues);
        //     setSelectedValue('');
        //     setSelectedValue2('');
        //     setSelectedFilePreview(null);
        //     setSelectedFile(null);
        //     toast.success('Template Created')
        //     navigate('/template-list')
        // }

        // setFormValues(initialValues)


        try {
            const result = await dispatch(createTemplateSlice(data)).unwrap();
            console.log('result-----', result)
            if (result) {
                resetForm();
                setFormValues(initialValues);
                setSelectedValue('');
                setSelectedValue2('');
                setSelectedFilePreview(null);
                setSelectedFile(null);
                toast.success('Template Created')
                navigate('/template-list')
            } else {
                console.error("Unexpected status:", result?.status);
            }
        } catch (error) {
            console.error("Error during admin signup:", error);
        }

        console.log("createTemplateData", data);
    };

    // Add a variable at the cursor position
    const addVariable = (e) => {
        const inputElement = textFieldRef.current;

        if (!inputElement) {
            console.error("TextField reference is null.");
            return;
        }
        const cursorPosition = inputElement.selectionStart;

        // Split text at the cursor position
        const beforeCursor = text.slice(0, cursorPosition);
        const afterCursor = text.slice(cursorPosition);

        // Add a new variable and update text
        const variable = `{{${nextVariableIndex}}}`;
        const updatedText = `${beforeCursor}${variable}${afterCursor}`;
        setText(updatedText);

        // Update variables after adding
        const reorderedText = reorderVariables(updatedText);
        setText(reorderedText);

        setNextVariableIndex((prevIndex) => prevIndex + 1);

        // Restore cursor position
        setTimeout(() => {
            const newCursorPosition = beforeCursor.length + variable.length;
            inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
        handleFormChange(e, "textBody", { ...e, textBody: reorderedText });
    };

    // Reorder variables to maintain sequential numbering
    const reorderVariables = (inputText) => {
        let variableIndex = 1;
        return inputText.replace(/\{\{\d+\}\}/g, () => `{{${variableIndex++}}}`);
    };

    // Handle text changes and reorder variables
    const handleTextChange = (event) => {
        const inputElement = textFieldRef.current;

        if (!inputElement) {
            console.error("TextField reference is null.");
            return;
        }
        const cursorPosition = inputElement.selectionStart;

        const updatedText = event.target.value;

        // Reorder variables dynamically
        const reorderedText = reorderVariables(updatedText);

        setText(reorderedText);

        // Restore cursor position
        setTimeout(() => {
            inputElement.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    console.log("selectedFilePreview",headerImage);

    return (
        <Box>
            <ToastContainer />

            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, resetForm); // Explicitly pass values and resetForm
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        console.log("values---", values);

                        return (
                            <Form>
                                <Grid container mt={4} spacing={2}>
                                    <Grid size={{ md: 8 }}>
                                        <Card
                                            sx={{
                                                p: 3,
                                                borderRadius: "15px",
                                                boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                                            }}
                                        >
                                            <Grid container spacing={3}>
                                                <Grid size={{ md: 6 }}>
                                                    <Typography className={classes.label}>
                                                        {t("templateList.templateName")}{" "}
                                                        <span style={{ color: "#FF0000" }}>*</span>
                                                    </Typography>
                                                    <Field
                                                        as={TextField}
                                                        name="templateName"
                                                        value={values.templateName}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            // handleFormChange(e);
                                                            handleFormChange(e, `templateName`, {
                                                                ...values,
                                                                templateName: e.target.value,
                                                            });
                                                        }}
                                                        onBlur={handleBlur}
                                                        onInput={(e) => {
                                                            const inputValue = e.target.value;
                                                            const filteredInput = inputValue.replace(
                                                                /[^a-z0-9_]/g,
                                                                ""
                                                            ); // Allow only lowercase letters, numbers, and underscores
                                                            e.target.value = filteredInput;
                                                        }}
                                                        error={Boolean(
                                                            touched.templateName && errors.templateName
                                                        )}
                                                        helperText={
                                                            touched.templateName && errors.templateName
                                                        }
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid size={{ md: 6 }}>
                                                    <Typography className={classes.label}>
                                                        {t("templateList.templateLanguage")}{" "}
                                                        <span style={{ color: "#FF0000" }}>*</span>
                                                    </Typography>
                                                    <Field
                                                        name="templateLanguage"
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                options={language}
                                                                value={
                                                                    values.templateLanguage === "en_US"
                                                                        ? "English"
                                                                        : values.templateLanguage
                                                                }
                                                                className={classes.selectField}
                                                                onChange={(e, value) => {
                                                                    setFieldValue(
                                                                        "templateLanguage",
                                                                        value?.value
                                                                    );

                                                                    handleFormChange(e, `templateLanguage`, {
                                                                        ...values,
                                                                        templateLanguage: value?.value,
                                                                    });
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder={t(
                                                                            "templateList.selectLanguage"
                                                                        )}
                                                                        error={Boolean(
                                                                            touched.templateLanguage &&
                                                                            errors.templateLanguage
                                                                        )}
                                                                        helperText={
                                                                            touched.templateLanguage &&
                                                                            errors.templateLanguage
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid container item size={{ md: 12 }} spacing={3}>
                                                    <Grid size={{ md: 6 }}>
                                                        <Typography className={classes.label}>
                                                            {t("templateList.templateStatus")}{" "}
                                                            <span style={{ color: "#FF0000" }}>*</span>
                                                        </Typography>
                                                        <Field
                                                            name="templateStatus"
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    options={["Active", "Inactive"]}
                                                                    value={values.templateStatus}
                                                                    className={classes.selectField}
                                                                    onChange={(e, value) => {
                                                                        setFieldValue("templateStatus", value);
                                                                        // handleFormChange({ target: { name: 'templateStatus', value } });
                                                                        handleFormChange(e, `templateStatus`, {
                                                                            ...values,
                                                                            templateStatus: value,
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder={t(
                                                                                "templateList.selectTemplateStatus"
                                                                            )}
                                                                            error={Boolean(
                                                                                touched.templateStatus &&
                                                                                errors.templateStatus
                                                                            )}
                                                                            helperText={
                                                                                touched.templateStatus &&
                                                                                errors.templateStatus
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ md: 12 }}>
                                                        <Typography className={classes.label}>
                                                            {t("templateList.templateCategory")}{" "}
                                                            <span style={{ color: "#FF0000" }}>*</span>
                                                        </Typography>
                                                        <Field
                                                            name="templateCategory"
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    options={[
                                                                        "MARKETING",
                                                                        "UTILITY",
                                                                        "AUTHENTICATION",
                                                                    ]} // Replace with actual categories
                                                                    value={values.templateCategory}
                                                                    // onChange={(e, value) => setFieldValue("templateCategory", value)}
                                                                    className={classes.selectField}
                                                                    onChange={(e, value) => {
                                                                        setFieldValue("templateCategory", value);
                                                                        // handleFormChange({ target: { name: 'templateCategory', value } });
                                                                        handleFormChange(e, `templateCategory`, {
                                                                            ...values,
                                                                            templateCategory: value,
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder={t(
                                                                                "templateList.selectTemplateCategory"
                                                                            )}
                                                                            error={Boolean(
                                                                                touched.templateCategory &&
                                                                                errors.templateCategory
                                                                            )}
                                                                            helperText={
                                                                                touched.templateCategory &&
                                                                                errors.templateCategory
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>

                                        {values.templateCategory !== "AUTHENTICATION" && (
                                            <Card
                                                sx={{
                                                    p: 3,
                                                    borderRadius: "15px",
                                                    boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                                                    marginTop: "24px",
                                                }}
                                            >
                                                <Grid container alignItems={"center"}>
                                                    <Grid size={{ md: 10 }}>
                                                        <Typography
                                                            sx={{
                                                                color: "#455967",
                                                                fontWeight: "500",
                                                                fontSize: "20px",
                                                            }}
                                                        >
                                                            {t("templateList.templateHeader")}{" "}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                color: "#A8A8A8",
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            {t("templateList.addATitle")}{" "}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid size={{ md: 2 }}>
                                                        <Chip
                                                            sx={{
                                                                width: "100%",
                                                                fontSize: "14px",
                                                                backgroundColor: "#445A68",
                                                                color: "#FFF",
                                                                height: "36px",
                                                                borderRadius: "20px",
                                                                padding: "4px",
                                                            }}
                                                            label={
                                                                selectedValue === "image"
                                                                    ? "Image"
                                                                    : selectedValue === "video"
                                                                        ? "Videos"
                                                                        : selectedValue === "document"
                                                                            ? "Documents"
                                                                            : selectedValue === "none"
                                                                                ? "None"
                                                                                : selectedValue === "text"
                                                                                    ? "Text"
                                                                                    : "Choose Media Type"
                                                            }
                                                            onClick={handleClick}
                                                            deleteIcon={
                                                                <ArrowDropDownIcon sx={{ fill: "#FFF" }} />
                                                            }
                                                            onDelete={() => { }}
                                                        />

                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleClose}
                                                        >
                                                            <MenuItem onClick={() => handleClose("text")}>
                                                                {t("templateList.text")}{" "}
                                                            </MenuItem>
                                                            <MenuItem onClick={() => handleClose("video")}>
                                                                {t("templateList.videos")}
                                                            </MenuItem>
                                                            <MenuItem onClick={() => handleClose("document")}>
                                                                {t("templateList.documents")}{" "}
                                                            </MenuItem>
                                                            <MenuItem onClick={() => handleClose("image")}>
                                                                {t("templateList.image")}{" "}
                                                            </MenuItem>
                                                            <MenuItem onClick={() => handleClose("none")}>
                                                                {t("templateList.none")}{" "}
                                                            </MenuItem>
                                                        </Menu>
                                                    </Grid>

                                                    <Grid size={{ md: 12 }}>
                                                        {selectedValue === "image" ? (
                                                            <>
                                                                <Divider sx={{ my: 2 }} />
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        marginTop: "12px",
                                                                        alignItems: "center",
                                                                        gap: "20px",
                                                                    }}
                                                                >
                                                                    {/* <img src={WhatsAppDemoImage}></img> */}
                                                                    <input
                                                                        type="file"
                                                                        // accept={
                                                                        //     selectedValue === "image"
                                                                        //         ? "image/*"
                                                                        //         : selectedValue === "videos"
                                                                        //             ? "video/*"
                                                                        //             : selectedValue === "documents"
                                                                        //                 ? ".pdf"
                                                                        //                 : ""
                                                                        // }
                                                                        accept={
                                                                            selectedValue === "image" ? "image/*" : ""
                                                                        }
                                                                        onChange={handleFileChange}
                                                                        style={{ display: "none" }}
                                                                        id="image-upload"
                                                                    />
                                                                    <label htmlFor="image-upload">
                                                                        <Chip
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                backgroundColor: "#445A68",
                                                                                color: "#FFF",
                                                                                height: "36px",
                                                                                borderRadius: "20px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            label="Upload"
                                                                        />
                                                                    </label>
                                                                    <Typography sx={{ color: "#A8A8A8" }}>
                                                                        {selectedFile
                                                                            ? "File Selected"
                                                                            : "No File Chosen"}
                                                                    </Typography>
                                                                    {selectedFile && (
                                                                        <IconButton onClick={handleRemoveFile}>
                                                                            <DeleteIcon sx={{ color: "#FA5A5A" }} />
                                                                        </IconButton>
                                                                    )}
                                                                </Box>
                                                            </>
                                                        ) : selectedValue === "text" ? (
                                                            <>
                                                                <Divider sx={{ my: 2 }} />
                                                                <Grid container>
                                                                    <Grid size={{ md: 12 }}>
                                                                        <Typography className={classes.label}>
                                                                            {t("templateList.header")}{" "}
                                                                        </Typography>
                                                                        <Field
                                                                            as={TextField}
                                                                            name="header"
                                                                            value={values.header}
                                                                            onChange={(e) => {
                                                                                handleChange(e); // Update Formik's internal state
                                                                                handleFormChange(e, `header`, {
                                                                                    ...values,
                                                                                    header: e.target.value,
                                                                                });
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            className={classes.textField}
                                                                            error={Boolean(
                                                                                touched.header && errors.header
                                                                            )}
                                                                            helperText={
                                                                                touched.header && errors.header
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        ) 
                                                        // selectedValue === "video" ? (
                                                        //     <>
                                                        //         <Divider sx={{ my: 2 }} />

                                                        //         <Box
                                                        //             sx={{
                                                        //                 display: "flex",
                                                        //                 marginTop: "12px",
                                                        //                 alignItems: "center",
                                                        //                 gap: "20px",
                                                        //             }}
                                                        //         >
                                                        //             {/* <img src={WhatsAppDemoImage}></img> */}
                                                        //             <input
                                                        //                 type="file"
                                                        //                 accept={
                                                        //                     selectedValue === "image"
                                                        //                         ? "image/*"
                                                        //                         : selectedValue === "videos"
                                                        //                             ? "video/*"
                                                        //                             : selectedValue === "documents"
                                                        //                                 ? ".pdf"
                                                        //                                 : ""
                                                        //                 }
                                                        //                 onChange={handleFileChange}
                                                        //                 style={{ display: "none" }}
                                                        //                 id="image-upload"
                                                        //             />
                                                        //             <label htmlFor="image-upload">
                                                        //                 <Chip
                                                        //                     sx={{
                                                        //                         fontSize: "16px",
                                                        //                         backgroundColor: "#445A68",
                                                        //                         color: "#FFF",
                                                        //                         height: "36px",
                                                        //                         borderRadius: "20px",
                                                        //                         cursor: "pointer",
                                                        //                     }}
                                                        //                     label="Upload"
                                                        //                 />
                                                        //             </label>
                                                        //             <Typography sx={{ color: "#A8A8A8" }}>
                                                        //                 {selectedFile
                                                        //                     ? "File Selected"
                                                        //                     : "No File Chosen"}
                                                        //             </Typography>
                                                        //             {selectedFile && (
                                                        //                 <IconButton onClick={handleRemoveFile}>
                                                        //                     <DeleteIcon sx={{ color: "#FA5A5A" }} />
                                                        //                 </IconButton>
                                                        //             )}
                                                        //         </Box>
                                                        //         {/* <img src={WhatsAppDemoVideo} width={'124px'} height={'124px'}></img> */}
                                                        //     </>
                                                        // ) : 
                                                        // selectedValue === "document" ? (
                                                        //     <>
                                                        //         <Divider sx={{ my: 2 }} />
                                                        //         <Box
                                                        //             sx={{
                                                        //                 display: "flex",
                                                        //                 marginTop: "12px",
                                                        //                 alignItems: "center",
                                                        //                 gap: "20px",
                                                        //             }}
                                                        //         >
                                                        //             {/* <img src={WhatsAppDemoImage}></img> */}
                                                        //             <input
                                                        //                 type="file"
                                                        //                 accept={
                                                        //                     selectedValue === "image"
                                                        //                         ? "image/*"
                                                        //                         : selectedValue === "videos"
                                                        //                             ? "video/*"
                                                        //                             : selectedValue === "documents"
                                                        //                                 ? ".pdf"
                                                        //                                 : ""
                                                        //                 }
                                                        //                 onChange={handleFileChange}
                                                        //                 style={{ display: "none" }}
                                                        //                 id="image-upload"
                                                        //             />
                                                        //             <label htmlFor="image-upload">
                                                        //                 <Chip
                                                        //                     sx={{
                                                        //                         fontSize: "16px",
                                                        //                         backgroundColor: "#445A68",
                                                        //                         color: "#FFF",
                                                        //                         height: "36px",
                                                        //                         borderRadius: "20px",
                                                        //                         cursor: "pointer",
                                                        //                     }}
                                                        //                     label="Upload"
                                                        //                 />
                                                        //             </label>
                                                        //             <Typography sx={{ color: "#A8A8A8" }}>
                                                        //                 {selectedFile
                                                        //                     ? "File Selected"
                                                        //                     : "No File Chosen"}
                                                        //             </Typography>
                                                        //             {selectedFile && (
                                                        //                 <IconButton onClick={handleRemoveFile}>
                                                        //                     <DeleteIcon sx={{ color: "#FA5A5A" }} />
                                                        //                 </IconButton>
                                                        //             )}
                                                        //         </Box>
                                                        //         {/* <img src={WhatsAppDemoPDF} width={'124px'} height={'124px'}></img> */}
                                                        //     </>
                                                        // ) 
                                                        : (
                                                            <></>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        )}

                                        <Grid container my={3}>
                                            <Grid size={{ md: 12 }}>
                                                <Typography
                                                    sx={{
                                                        color: "#455967",
                                                        fontWeight: "500",
                                                        fontSize: "20px",
                                                    }}
                                                >
                                                    {t("templateList.templateHeader")}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "#A8A8A8",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {t("templateList.addTextBody")}
                                                </Typography>

                                                <Card
                                                    sx={{
                                                        p: 3,
                                                        borderRadius: "15px",
                                                        boxShadow:
                                                            "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                                                        marginTop: "24px",
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid size={{ md: 12 }}>
                                                            <Typography className={classes.label}>
                                                                {t("templateList.textBody")}{" "}
                                                                <span style={{ color: "#FF0000" }}>*</span>
                                                            </Typography>
                                                            <Field
                                                                as={TextField}
                                                                name="textBody"
                                                                value={text}
                                                                inputRef={textFieldRef}
                                                                onChange={(e) => {
                                                                    handleChange(e); // Update Formik's internal state
                                                                    handleFormChange(e, `textBody`, {
                                                                        ...values,
                                                                        textBody: e.target.value,
                                                                    });
                                                                    handleTextChange(e);
                                                                    // handleFormChange(e); // Update your custom formValues state
                                                                }}
                                                                onBlur={handleBlur}
                                                                className={classes.textField}
                                                                error={Boolean(
                                                                    touched.textBody && errors.textBody
                                                                )}
                                                                helperText={touched.textBody && errors.textBody}
                                                            />
                                                        </Grid>

                                                        <Grid size={{ md: 12 }} mt={1}>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <Chip
                                                                    onClick={() =>
                                                                        addVariable({ ...values, textBody: text })
                                                                    }
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        backgroundColor: "#445A68",
                                                                        color: "#FFF",
                                                                        height: "36px",
                                                                        borderRadius: "20px",
                                                                    }}
                                                                    label="Add Variable"
                                                                    icon={<AddIcon style={{ fill: "#FFF" }} />}
                                                                />
                                                                <Box>
                                                                    <IconButton>
                                                                        <FormatBold />
                                                                    </IconButton>
                                                                    <IconButton>
                                                                        <FormatItalic />
                                                                    </IconButton>
                                                                    <IconButton>
                                                                        <FormatUnderlined />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid>

                                        <Grid container my={3}>
                                            <Grid size={{ md: 12 }}>
                                                <Typography
                                                    sx={{
                                                        color: "#455967",
                                                        fontWeight: "500",
                                                        fontSize: "20px",
                                                    }}
                                                >
                                                    {t("templateList.templateFooter")}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "#A8A8A8",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {t("templateList.addTextFooter")}
                                                </Typography>

                                                <Card
                                                    sx={{
                                                        p: 3,
                                                        borderRadius: "15px",
                                                        boxShadow:
                                                            "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                                                        marginTop: "24px",
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid size={{ md: 12 }}>
                                                            <Typography className={classes.label}>
                                                                {t("templateList.footerText")}{" "}
                                                                <span style={{ color: "#FF0000" }}>*</span>
                                                            </Typography>
                                                            <Field
                                                                as={TextField}
                                                                name="footerText"
                                                                value={values.footerText}
                                                                onChange={(e) => {
                                                                    handleChange(e); // Update Formik's internal state
                                                                    handleFormChange(e, `footerText`, {
                                                                        ...values,
                                                                        footerText: e.target.value,
                                                                    });
                                                                }}
                                                                onBlur={handleBlur}
                                                                className={classes.textField}
                                                                error={Boolean(
                                                                    touched.footerText && errors.footerText
                                                                )}
                                                                helperText={
                                                                    touched.footerText && errors.footerText
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid>

                                        <Grid container my={3}>
                                            <Grid size={{ md: 12 }}>
                                                <Typography
                                                    sx={{
                                                        color: "#455967",
                                                        fontWeight: "500",
                                                        fontSize: "20px",
                                                    }}
                                                >
                                                    {t("templateList.templateButtons")}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "#A8A8A8",
                                                        fontWeight: "400",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {t("templateList.chooseButtonType")}{" "}
                                                </Typography>

                                                <Card
                                                    sx={{
                                                        p: 3,
                                                        borderRadius: "15px",
                                                        boxShadow:
                                                            "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                                                        marginTop: "24px",
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            size={{ md: 12 }}
                                                            display={"flex"}
                                                            justifyContent={"space-between"}
                                                            alignItems={"center"}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: "#A8A8A8",
                                                                    fontWeight: "400",
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                {t("templateList.chooseButtonType")}
                                                            </Typography>

                                                            <Chip
                                                                sx={{
                                                                    fontSize: "16px",
                                                                    backgroundColor: "#445A68",
                                                                    color: "#FFF",
                                                                    height: "36px",
                                                                    borderRadius: "20px",
                                                                }}
                                                                label={
                                                                    selectedValue2 === "callToAction"
                                                                        ? "Call To Action"
                                                                        : selectedValue2 === "quickReply"
                                                                            ? "Quick Reply"
                                                                            : selectedValue2 === "none"
                                                                                ? "None"
                                                                                : "Button Type"
                                                                }
                                                                onClick={handleButtonTypeClick}
                                                                deleteIcon={
                                                                    <ArrowDropDownIcon sx={{ fill: "#FFF" }} />
                                                                }
                                                                onDelete={() => { }}
                                                            />

                                                            <Menu
                                                                anchorEl={anchorE2}
                                                                open={Boolean(anchorE2)}
                                                                onClose={handleButtonTypeClose}
                                                            >
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleButtonTypeClose("callToAction")
                                                                    }
                                                                >
                                                                    {t("templateList.callToAction")}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleButtonTypeClose("quickReply")
                                                                    }
                                                                >
                                                                    {t("templateList.quickReply")}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => handleButtonTypeClose("none")}
                                                                >
                                                                    {t("templateList.none")}
                                                                </MenuItem>
                                                            </Menu>
                                                        </Grid>

                                                        {selectedValue2 === "callToAction" && (
                                                            <>
                                                                <Grid container item spacing={2} sx={{ mt: 2 }}>
                                                                    {values.buttonRows.map((row, index) => (
                                                                        <>
                                                                            <Grid size={{ md: 12 }}>
                                                                                <Divider />
                                                                            </Grid>

                                                                            {/* Button Text */}
                                                                            <Grid size={{ md: 3 }}>
                                                                                <Typography className={classes.label}>
                                                                                    {t("templateList.buttonText")}
                                                                                </Typography>
                                                                                <TextField
                                                                                    className={classes.textField}
                                                                                    value={row.buttonText}
                                                                                    onChange={(e) => {
                                                                                        handleFormChange(e, `buttonText`, {
                                                                                            ...values,
                                                                                            buttonRows: values.buttonRows.map(
                                                                                                (item) =>
                                                                                                    item.id === row.id
                                                                                                        ? {
                                                                                                            ...item,
                                                                                                            buttonText:
                                                                                                                e.target.value,
                                                                                                        }
                                                                                                        : item
                                                                                            ),
                                                                                        });
                                                                                        setFieldValue(
                                                                                            `buttonRows[${index}].buttonText`,
                                                                                            e.target.value
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Grid>

                                                                            {/* Action Type */}
                                                                            <Grid size={{ md: 2.5 }}>
                                                                                <Typography className={classes.label}>
                                                                                    {t("templateList.actionType")}
                                                                                </Typography>
                                                                                <Autocomplete
                                                                                    options={actionTypeList}
                                                                                    value={row.actionType}
                                                                                    className={classes.selectField}
                                                                                    onChange={(e, value) => {
                                                                                        handleFormChange(e, `actionType`, {
                                                                                            ...values,
                                                                                            buttonRows: values.buttonRows.map(
                                                                                                (item) =>
                                                                                                    item.id === row.id
                                                                                                        ? {
                                                                                                            ...item,
                                                                                                            actionType:
                                                                                                                e.target.value,
                                                                                                        }
                                                                                                        : item
                                                                                            ),
                                                                                        });
                                                                                        setFieldValue(
                                                                                            `buttonRows[${index}].actionType`,
                                                                                            value
                                                                                        );
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} />
                                                                                    )}
                                                                                />
                                                                            </Grid>

                                                                            {/* URL Type */}
                                                                            <Grid size={{ md: 2.5 }}>
                                                                                <Typography className={classes.label}>
                                                                                    {t("templateList.urlType")}
                                                                                </Typography>
                                                                                <Autocomplete
                                                                                    options={urlTypeList}
                                                                                    // getOptionLabel={(option) => option.label}
                                                                                    value={row.urlType}
                                                                                    className={classes.selectField}
                                                                                    onChange={(e, value) => {
                                                                                        handleFormChange(e, `urlType`, {
                                                                                            ...values,
                                                                                            buttonRows: values.buttonRows.map(
                                                                                                (item) =>
                                                                                                    item.id === row.id
                                                                                                        ? {
                                                                                                            ...item,
                                                                                                            urlType: e.target.value,
                                                                                                        }
                                                                                                        : item
                                                                                            ),
                                                                                        });

                                                                                        setFieldValue(
                                                                                            `buttonRows[${index}].urlType`,
                                                                                            value
                                                                                        );
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} />
                                                                                    )}
                                                                                />
                                                                            </Grid>

                                                                            {/* Website URL */}
                                                                            <Grid size={{ md: 3 }}>
                                                                                <Typography className={classes.label}>
                                                                                    {t("templateList.websiteUrl")}
                                                                                </Typography>
                                                                                <TextField
                                                                                    className={classes.textField}
                                                                                    value={row.websiteUrl}
                                                                                    onChange={(e) => {
                                                                                        handleFormChange(e, `websiteUrl`, {
                                                                                            ...values,
                                                                                            buttonRows: values.buttonRows.map(
                                                                                                (item) =>
                                                                                                    item.id === row.id
                                                                                                        ? {
                                                                                                            ...item,
                                                                                                            websiteUrl:
                                                                                                                e.target.value,
                                                                                                        }
                                                                                                        : item
                                                                                            ),
                                                                                        });

                                                                                        setFieldValue(
                                                                                            `buttonRows[${index}].websiteUrl`,
                                                                                            e.target.value
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Grid>

                                                                            {/* Delete Button */}
                                                                            <Grid
                                                                                size={{ md: 1 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "end",
                                                                                }}
                                                                            >
                                                                                <IconButton
                                                                                    sx={{ marginBottom: "12px" }}
                                                                                    onClick={() =>
                                                                                        handleDeleteButtonRow(
                                                                                            index,
                                                                                            setFieldValue,
                                                                                            values
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <DeleteIcon
                                                                                        sx={{ fill: "#FA5A5A" }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </>
                                                                    ))}
                                                                    {/* Add Button */}
                                                                    <Grid
                                                                        size={{ md: 12 }}
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            startIcon={<AddIcon />}
                                                                            sx={{
                                                                                borderRadius: "40px",
                                                                                padding: "12px",
                                                                                // backgroundColor: '#445A68',
                                                                                color: "#445A68",
                                                                                fontSize: "18px",
                                                                                fontWeight: "600",
                                                                                border:
                                                                                    "1px solid rgba(68, 90, 104, 1)",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleAddButtonRow(
                                                                                    setFieldValue,
                                                                                    values
                                                                                )
                                                                            }
                                                                        >
                                                                            {t("templateList.addButton")}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid>

                                        {/* <Grid container my={3}>
                                        <Grid size={{ md: 12 }}>
                                            <Typography sx={{ color: '#455967', fontWeight: '500', fontSize: '20px' }}>Add Sample Content</Typography>
                                            <Typography sx={{ color: '#A8A8A8', fontWeight: '400', fontSize: '16px' }}>This helps to review and approval process. So that we can understand what message we are planning to send</Typography>
                                        </Grid>

                                        <Grid size={{ md: 6 }} marginTop={3}>
                                            <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                                                <Typography sx={{ color: '#A8A8A8', fontWeight: '500', fontSize: '18px' }}>Header Variable 1</Typography>
                                                <Box sx={{ display: 'flex', marginTop: '12px', alignItems: 'center', gap: '20px' }}>
                                                    <input
                                                        type="file"
                                                        accept={
                                                            selectedValue === 'image'
                                                                ? 'image/*'
                                                                : selectedValue === 'videos'
                                                                    ? 'video/*'
                                                                    : selectedValue === 'documents'
                                                                        ? '.pdf'
                                                                        : ''
                                                        }
                                                        onChange={handleFileChange}
                                                        style={{ display: 'none' }}
                                                        id="image-upload"
                                                    />
                                                    <label htmlFor="image-upload">
                                                        <Chip
                                                            sx={{ fontSize: '16px', backgroundColor: '#445A68', color: '#FFF', height: '36px', borderRadius: '20px', cursor: 'pointer' }}
                                                            label="Upload"
                                                        />
                                                    </label>
                                                    <Typography sx={{ color: '#A8A8A8' }}>
                                                        {selectedFile ? "File Selected" : "No File Chosen"}
                                                    </Typography>
                                                    {selectedFile && (
                                                        <IconButton onClick={handleRemoveFile}>
                                                            <DeleteIcon sx={{ color: "#FA5A5A" }} />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </Card>
                                        </Grid>

                                    </Grid> */}
                                    </Grid>

                                    <Grid
                                        size={{ md: 4 }}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ChatInterface
                                            formValues={formValues}
                                            selectedValue={selectedValue}
                                            selectedFile={selectedFile}
                                            headerImage={headerImage}
                                            selectedFilePreview={selectedFilePreview}
                                            dataToShow={values.templateCategory}
                                        />
                                    </Grid>
                                </Grid>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "10px",
                                        paddingY: "10px",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        startIcon={<ArrowBackIcon />}
                                        sx={{
                                            borderRadius: "40px",
                                            padding: "15px 30px",
                                            backgroundColor: "#445A68",
                                            color: "#FFFFFF",
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            border: "1px solid rgba(68, 90, 104, 1)",
                                        }}
                                    >
                                        {t("back")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        endIcon={<ArrowForwardIcon />}
                                        sx={{
                                            borderRadius: "40px",
                                            padding: "15px 30px",
                                            color: "#FFFFFF",
                                            background:
                                                "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            border: "1px solid #13BECF",
                                        }}
                                        type="submit"
                                    >
                                        {t("templateList.submitForPreview")}
                                    </Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};

export default CreateTemplateFinal;
