// import axios from "axios";
// import { toast } from "react-toastify";

// // Create an Axios instance
// const axiosInstance = axios.create({
//   baseURL: "https://api.qa.dxe.whilter.ai/", // Base URL for all API requests
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // Request interceptor to attach tokens or modify requests globally
// axiosInstance.interceptors.request.use(
//   (config) => {
//     // Uncomment the following lines if you want to use token authentication in the future
//     /*
//     const token = localStorage.getItem('token'); // Assume token is stored in localStorage
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     */
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Response interceptor for error handling globally
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { status } = error.response;
//     if (status === 401) {
//       // Handle unauthorized errors (e.g., log out the user)
//       toast.error("Unauthorized! Please log in again.");
//     }
//     // You can handle more status codes here
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom"; // If using React Router

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://api.qa.dxe.whilter.ai/", // Base URL for all API requests
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to attach tokens or modify requests globally
axiosInstance.interceptors.request.use(
  (config) => {
    if (
      config.url.includes("login") ||
      config.url.includes("forgot-password")
    ) {
      return config;
    }

    const token = localStorage.getItem("idToken"); // Assume token is stored in localStorage

    // If token exists, check expiration time
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        // Token is expired, clear local storage and redirect to login
        localStorage.removeItem("token");
        toast.error("Session expired! Please log in again.");
        window.location.href = "/login"; // Redirect to login page
        return Promise.reject("Token expired");
      }

      // If token is valid, add it to headers
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for error handling globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      // Handle unauthorized errors (e.g., log out the user)
      toast.error("Unauthorized! Please log in again.");
      localStorage.removeItem("token");
      window.location.href = "/login"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
