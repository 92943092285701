import React, { useEffect } from 'react'
import CustomModal from '../../../components/CustomModal/CustomModal'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, CircularProgress, Grid2, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createAttributeMappingSlice, fetchAttributesCategoryListSlice, fetchAttributesCategorySlice, fetchAttributesMappingSlice } from '../../../redux/superAdmin/superAdmin.slice';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const AddAttributesModal = ({ open, handleClose, attributeCategoryList, page, rowsPerPage }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { attributeCategoryOptions, dropdownLoader } = useSelector(
        (state) => state.superAdmin
    );


    // fetchAttributesCategoryListSlice

    const initialValues = {
        category: '',
        selectedCategoryOptions: '',
    }

    const validationSchema = Yup.object().shape({

    })

    const handleSelectCategory = async (event, value) => {

        formik.setFieldValue("category", value ? value : "");

        const result = await dispatch(fetchAttributesCategoryListSlice(value?.id)).unwrap();
    }

    // const handleSubmit = (values) => {

    //     const payload = {
    //         attributeName: values.selectedCategoryOptions?.name
    //     }

    //     const result = dispatch(createAttributeMappingSlice(payload)).unwrap();

    //     if (result) {

    //         const successResult = dispatch(fetchAttributesMappingSlice({ page, rowsPerPage })).unwrap();

    //         if (successResult) {
    //             handleClose();
    //         }
    //     }
    // }

    const handleSubmit = async (values) => {
        try {
            const payload = {
                attributeName: values.selectedCategoryOptions?.name,
            };
    
            await dispatch(createAttributeMappingSlice(payload)).unwrap();

            toast.success("Attribute mapping created successfully!");
    
            await dispatch(fetchAttributesMappingSlice({ page, rowsPerPage })).unwrap();
    
            handleClose();
        } catch (error) {
            toast.error("An error occurred:", error);
            // Optionally, show an error notification to the user
        }
    };
    

    const formik = useFormik({

        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <CustomModal
            open={open}
            onClose={() => {
                handleClose();
            }}
            heading={"Add Attributes"}
            button={true}
            customWidth={"710px"}
        >
            <form onSubmit={formik.handleSubmit}>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12 }}>
                        <Typography className={classes.label}>
                            Category{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                        </Typography>
                        <Autocomplete
                            name="category"
                            options={attributeCategoryList || []}
                            getOptionLabel={(option) =>
                                option?.label ? option.label : ""
                            }
                            onChange={(e, value) => {
                                handleSelectCategory(e, value)

                            }}
                            onBlur={formik.handleBlur}
                            className={classes.selectField}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Please Select Category"
                                    error={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={
                                        formik.touched.category && formik.errors.category
                                    }
                                    className={classes.textField}
                                />
                            )}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <Typography className={classes.label}>
                             Atribute{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                        </Typography>
                        <Autocomplete
                            name="selectedCategoryOptions"
                            options={attributeCategoryOptions || []}
                            getOptionLabel={(option) =>
                                option?.name ? option.name : ""
                            }
                            onChange={(e, value) => {
                                formik.setFieldValue("selectedCategoryOptions", value ? value : "");

                            }}
                            loading={dropdownLoader}
                            onBlur={formik.handleBlur}
                            className={classes.selectField}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Please Select Atribute"
                                    error={
                                        formik.touched.selectedCategoryOptions && Boolean(formik.errors.selectedCategoryOptions)
                                    }
                                    helperText={
                                        formik.touched.selectedCategoryOptions && formik.errors.selectedCategoryOptions
                                    }
                                    className={classes.textField}
                                    slotProps={{
                                        input: {
                                          ...params.InputProps,
                                          endAdornment: (
                                            <React.Fragment>
                                              {dropdownLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                              {params.InputProps.endAdornment}
                                            </React.Fragment>
                                          ),
                                        },
                                      }}
                            
                                />
                            )}
                        />
                    </Grid2>
                </Grid2>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                        sx={{
                            borderRadius: '10px',
                            color: '#FFFFFF !important',
                            fontSize: '18px !important',
                            background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
                            padding: '8px 16px !important',
                            textTransform: 'capitalize'
                        }}
                        type='submit'
                    >
                        Submit
                    </Button>
                </Box>
            </form>
        </CustomModal>
    )
}

export default AddAttributesModal