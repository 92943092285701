import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTeamsDetailsSlice, SearchCampaignListSlice } from "../redux/teamSlice/team.slice";
import { Typography, Button, Box, TextField, InputAdornment, Autocomplete } from "@mui/material";
import CampaignTable from "../components/table";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";
import CustomBreadcrumb from "../components/CustomBreadcrumb/CustomBreadcrumb";
import Grid from "@mui/material/Grid2";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
  },

  selectField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
  },

  
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));


const dropdownOptions = {
  campaignType: [
    { label: "Lead Generation", value: "LEADGENERATION" },
    { label: "Brand Awareness", value: "BRANDAWARENESS" },
    { label: "Website Traffic", value: "WEBSITETRAFFIC" },
    { label: "Sales", value: "SALES" },
  ],
  channel: [
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WHATSAPP" },
  ],
  action: [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ],
  status: [
    { label: "Draft", value: "DRAFT" },
    { label: "Pending", value: "Pending" },
    { label: "Rejected", value: "Rejected" },
    { label: "Approved", value: "Approved" },
    { label: "Executed", value: "Executed" },
  ],
};



const CampaignList = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const breadcrumb = [
    {
      title: t('home'),
      href: '/dashboard',
    },
    {
      title: t('campaignList.listOfCampaigns'),
      href: '/campaign-list',
    }
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { campaigns, totalItems, loading } = useSelector(
    (state) => state.teamsDetails
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [campaignName, setCampaignName] = useState('');
  const [filters, setFilters] = useState({
    campaignType: null,
    channel: null,
    action: null,
    status: null,
  });



  // Fetch campaigns when page or rowsPerPage change
  useEffect(() => {
    dispatch(fetchTeamsDetailsSlice({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  // Handle page change in table
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setCampaignName(event.target.value)

    var payload = {
      data: [
        {
          campaignName: event.target.value ? event.target.value : '',
        },
        {
          campaignType: filters?.campaignType?.value ? filters?.campaignType?.value : '',
        },
        {
          active: filters?.action?.value ? filters?.action?.value : '',
        },
        {
          status: filters?.status?.value ? filters?.status?.value : '',
        },
        {
          channel: filters?.channel?.value ? filters?.channel?.value : '',
        },
      ],
      page: page || 0,
      size: rowsPerPage || 10
    }

    setCampaignName(event.target.value);

    dispatch(SearchCampaignListSlice(payload))
  }

  const handleDropdownChange = (field) => (event, newValue) => {
    const updatedValue = { [field]: newValue };

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedValue,
    }));

    const payload = {
      data: [
        {
          campaignName: campaignName || "",
        },
        {
          campaignType:
            field === "campaignType" ? newValue?.value || "" : filters?.campaignType?.value || "",
        },
        {
          active: field === "action" ? newValue?.value || "" : filters?.action?.value || "",
        },
        {
          status: field === "status" ? newValue?.value || "" : filters?.status?.value || "",
        },
        {
          channel: field === "channel" ? newValue?.value || "" : filters?.channel?.value || "",
        },
      ],
      page: page || 0,
      size: rowsPerPage || 10,
    };

    dispatch(SearchCampaignListSlice(payload));
  };



  return (
    <Box>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '16px' }}>
        <Typography sx={{ color: '#445A68', fontSize: '29px', fontWeight: '600' }}>{t('campaignList.campaignList')}</Typography>
      </Box>
      
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "16px",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: "40px",
            // padding: "15px 30px",
            color: "#445A68",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #445A68",
          }}
          onClick={() => {
            navigate("/create-campaign");
          }}
        >
          {t('campaignList.newCampaign')}
        </Button>
      </Box>

      <Grid container mt={3} spacing={2}>
        <Grid size={{ md: 4 }}>
          <TextField
            variant="outlined"
            placeholder={t('campaignList.searchByCategoryName')}
            fullWidth
            className={classes.searchBar}
            value={campaignName}
            onChange={e => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {Object.entries(dropdownOptions).map(([key, options]) => (
          <Grid key={key} size={{ md: 2 }}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.label}
              value={filters[key]}
              onChange={handleDropdownChange(key)} d
              className={classes.selectField}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.textField}
                  placeholder={ key === 'campaignType' ? t('campaignList.searchByCategoryType') : key === 'channel' ? t('campaignList.searchByChannel') : key === 'action' ? t('campaignList.searchByAction') : key === 'status' ? t('campaignList.searchByStatus') : key}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>

      <CampaignTable
        campaigns={campaigns}
        totalItems={totalItems}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};

export default CampaignList;
