import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  TablePagination,
  Card,
  Typography,
  Breadcrumbs,
  Grid2,
  Chip,
  Button
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { fetchContentListSlice, SearchContentListSlice } from "../../redux/stepperSlice/stepper.slice";
import CustomModal from "../../components/CustomModal/CustomModal";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ContactsIcon from "@mui/icons-material/Contacts";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MmsIcon from "@mui/icons-material/Mms";
import DateRangeIcon from '@mui/icons-material/DateRange';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import BadgeIcon from '@mui/icons-material/Badge';
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";
import WhatsAppDemoVideo from '../../assets/DemoVideo.jpg'
import CustomBreadcrumb from "../../components/CustomBreadcrumb/CustomBreadcrumb";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));
const statusStyles = {
  active: {
    backgroundColor: "#e0f7e9",
    color: "#34a853",
  },
  offline: {
    backgroundColor: "#f5f5f5",
    color: "#9e9e9e",
  },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#455967",
  fontWeight: "500",
  lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#455967",
  fontWeight: "500",
}));


function formatDate(dateString) {
  if (!dateString) {
    return false
  }
  const date = new Date(dateString);

  // Options for formatting
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    //   hour: "numeric",
    //   minute: "numeric",
    //   hour12: true, // Use 12-hour format
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}


const ContentLibrary = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const breadcrumb = [
    {
      title: t('home'),
      href: '/dashboard',
    },
    {
      title: t('contentLibrary.contentLibrary'),
      href: '',
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listContentData, totalItems, loading } = useSelector(
    (state) => state.stepper
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [popupImage, setPopUpImage] = useState("");

  const [selected, setSelected] = useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Function to handle row selection
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Function to handle selecting all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = listContentData.map((item) => item.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    dispatch(fetchContentListSlice({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleSearchChange = (event) => {

    var data = { page: page, value: event.target.value }
    setSearchQuery(event.target.value);
    dispatch(SearchContentListSlice(data))
  };

  const handleImageClick = (item) => {
    setPopUpImage({
      fileType: item?.fileType,
      media: item?.content
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPopUpImage("");
  };

  useEffect(() => {
    dispatch(fetchContentListSlice({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  console.log("listContentData----", listContentData);

  return (
    <Box>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      <Grid2 container sx={{ justifyContent: "end" }} mt={3}>

        <Grid2 size={{ xs: 12, md: 8 }}>
          <Typography
            sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
          >
            {t('contentLibrary.contentLibrary')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <TextField
            variant="outlined"
            placeholder={t('search')}
            fullWidth
            className={classes.searchBar}
            sx={{ color: "#ECECEC" }}
            value={searchQuery}
            onChange={e => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
      </Grid2>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: "40px",
            // padding: "15px 30px",
            color: "#445A68",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #445A68",
          }}
          onClick={() => { navigate('/create-content') }}
        >
          {t('contentLibrary.contentLibrary')}
        </Button>

        <Button
          disabled
          variant="contained"
          endIcon={<SortIcon />}
          sx={{
            color: "#fff",
            backgroundColor: "#13BECF",
            borderRadius: "10px",
            fontSize: "16px",
            fontWeight: "500",
            borderRadius: "6px",
            // border: "1px solid #13BECF",
            cursor: "not-allowed ",
          }}
        >
          {t('sort')}
        </Button>

      </Box>

      <TableContainer
        component={Paper}
        sx={{ mt: 3, border: "1px solid #D4D4D4", borderRadius: "12px" }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="campaign table">
            <TableHead sx={{ background: '#F1F1F1' }}>
              <TableRow>
                {/* <TableCell>
                  <Checkbox
                    sx={{
                      "&.MuiCheckbox-indeterminate": {
                        color: "#13BECF",
                      },
                      "&.Mui-checked": {
                        color: "#13BECF",
                      },
                    }}
                    color="primary"
                    indeterminate={
                      selected?.length > 0 &&
                      selected?.length < listContentData?.length
                    }
                    checked={
                      listContentData?.length > 0 &&
                      selected?.length === listContentData?.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell> */}
                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <MmsIcon fontSize={"14px"} sx={{ color: "#455967" }} />
                    <TableHeadTitle>{t('stepper.campaignDetails.media')}</TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>

                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <LocationOnIcon
                      fontSize={"14px"}
                      sx={{ color: "#455967" }}
                    />
                    <TableHeadTitle>{t('templateList.templateType')}</TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>

                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <BadgeIcon
                      fontSize={"14px"}
                      sx={{ color: "#455967" }}
                    />
                    <TableHeadTitle>{t('templateList.templateName')}</TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>
                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <DateRangeIcon fontSize={"14px"} sx={{ color: "#455967" }} />
                    <TableHeadTitle>{t('contentLibrary.createdAt')}</TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>


                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <NewReleasesIcon
                      fontSize={"14px"}
                      sx={{ color: "#455967" }}
                    />
                    <TableHeadTitle>{t('templateList.status')}</TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>

                <TableCell sx={{ minWidth: '100px' }}>
                  {/* <BoxContainer>
                                        <TableHeadTitle>Actions</TableHeadTitle>
                                        <SwapVertIcon fontSize={'14px'} sx={{ color: "#455967" }} />
                                    </BoxContainer> */}
                </TableCell>


              </TableRow>
            </TableHead>

            <TableBody>
              {listContentData?.map((item, key) => {
                const isItemSelected = isSelected(item.id);
                const labelId = `campaign-table-checkbox-${item.id}`;
                console.log("type--", item.fileType)
                return (
                  <StyledTableRow
                    onClick={(event) => handleClick(event, item.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={item.id}
                    selected={isItemSelected}
                  >
                    {/* <TableCell>
                      <Checkbox
                        color="primary"
                        sx={{
                          "&.Mui-checked": {
                            color: "#13BECF",
                          },
                        }}
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell> */}
                    <TableCell>
                      {item?.fileType?.includes('video') ?
                        <>
                          <img
                            src={WhatsAppDemoVideo}
                            width={50}
                            height={50}
                            alt="Logo"
                            style={{ cursor: "pointer", borderRadius: "6px" }}
                            onClick={() => handleImageClick(item)}
                          />
                        </> : <>
                          <img
                            src={item?.content}
                            width={50}
                            height={50}
                            alt="Logo"
                            style={{ cursor: "pointer", borderRadius: "6px" }}
                            onClick={() => handleImageClick(item)}
                          />
                        </>
                      }
                    </TableCell>

                    {/* <TableCell>{item?.org?.firstName}{" "}{item?.org?.lastName}</TableCell> */}
                    <TableCell>
                      <TableDataTitle>{item?.contentType}</TableDataTitle>
                    </TableCell>
                    {/* <TableCell>{item?.org?.firstName}{" "}{item?.org?.lastName}</TableCell> */}
                    <TableCell>
                      <TableDataTitle>{item?.name}</TableDataTitle>
                    </TableCell>

                    <TableCell>
                      <TableDataTitle>{formatDate(item?.createdOn)}</TableDataTitle>
                    </TableCell>

                    <TableCell>
                      <Chip
                        label={`• ${item.status === "ACTIVE" ? "Active" : "Inactive"}`} // Adds the dot before the text
                        sx={{
                          ...statusStyles[
                          item.status === 'ACTIVE' ? "active" : "offline"
                          ],
                          fontWeight: "bold",
                          fontSize: "14px",
                          padding: "2px 6px",
                          borderRadius: "2px",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton disabled={true} color="#455967">
                        <EditIcon />
                      </IconButton>
                      <IconButton disabled={true} color="#455967">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {/* <CustomPagination
        rowsPerPageOptions={[5, 10, 25]}
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, newPage) => handlePageChange(newPage)}
        onRowsPerPageChange={(e) =>
          handleRowsPerPageChange(parseInt(e.target.value, 10))
        }
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Center CustomPagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <CustomPagination
            rowsPerPageOptions={[5, 10, 25]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(e) =>
              handleRowsPerPageChange(parseInt(e.target.value, 10))
            }
          />
        </Box>

        {/* Typography aligned to the end */}
        <Box
          sx={{
            flex: "0 0 auto",
          }}
        >
          <Typography sx={{
            fontSize: '19px',
            fontWeight: '600',
            background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}>
            {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
          </Typography>
        </Box>
      </Box>

      <CustomModal
        open={openDialog}
        onClose={handleCloseDialog}
        heading={"Preview"}
      >
        {popupImage?.fileType?.includes('video') ?
          <>
            <video controls width={'525px'}>
              <source
                src={popupImage.media}
                type="video/mp4"
              />
            </video>
          </> : <>
            <img
              // src={`data:image/png;base64,${popupImage}`}
              src={popupImage.media}
              alt="Full-size logo"
              style={{ width: "100%", height: "auto" }}
            />
          </>}
      </CustomModal>
    </Box>
  );
};

export default ContentLibrary;
