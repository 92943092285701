import axiosInstance from "../../components/utils/axiosInstance";
import { toast } from "react-toastify";
import axios from "axios";

export const fetchTeamsDetailsThunk = async (page, rowsPerPage) => {
  try {
    const token = localStorage.getItem("idToken");
    if (!token) {
      toast.error("Unauthorized access. Please log in.");
    }
    const response = await axiosInstance.get("api/cm/campaign/list", {
      params: {
        pageNumber: page?.page,
        pageSize: page?.rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};



export const fetchTeamMemberListThunk = async () => {
  try {
    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      toast.error("Unauthorized access. Please log in.");
    }

    const response = await axiosInstance.get("user/organization", {
      method: "GET", // method
      headers: {
        Authorization: `Bearer ${idToken}`, // Pass the token in the Authorization header
        //   "Content-Type": "application/json", // Optional
      },
    });
    // const data = await response.json();

    return response.data;
  } catch (error) {
  }
};

export const fetchDataRequestDataThunk = async (page, rowsPerPage) => {
  try {
    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      toast.error("Unauthorized access. Please log in.");
    }

    const response = await axiosInstance.get("api/dr/dataRequest", {
      params: {
        pageNumber: page?.page,
        pageSize: page?.rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// Thunk for fetching notifications
export const fetchNotificationsThunk =
  (pageNumber, pageSize) => async (dispatch) => {
    try {
      const token = localStorage.getItem("idToken");
      if (!token) {
        toast.error("Unauthorized access. Please log in.");
        return;
      }

      const response = await axiosInstance.get(
        "/api/cm/user-notification/orgId",
        {
          params: {
            pageNumber,
            pageSize,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to fetch notifications"
      );
    }
  };

// Thunk for fetching notifications

export const fetchNotificationPreviewThunk = async (page) => {
  try {
    const token = localStorage.getItem("idToken");
    if (!token) {
      toast.error("Unauthorized access. Please log in.");
      return;
    }

    const response = await axiosInstance.get(
      "/api/cm/user-notification/orgId",
      {
        params: {
          pageNumber: page?.pageNumber,
          pageSize: page?.pageSize,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    toast.error(
      error.response?.data?.message || "Failed to fetch notifications"
    );
  }
};

export const fetchCampaignListDeleteThunk = async (data) => {
  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }

    const response = await axiosInstance.delete(`api/cm/campaign/delete`, {
      params: {
        campaignId: data.campaignId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return toast.error(error?.response?.data?.message);
  }
};

export const fetchCampaignDetailsThunk = async (id) => {
  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }
    const response = await axiosInstance.get(`/api/cm/campaign/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // const data = await response.json();

    return response.data;
  } catch (error) {
  }
};

export const NotificationApproveThunk = async (data) => {
  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }

    const response = await axiosInstance.post(
      `api/ecomm/trigger/intersection/data?id=${data.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    toast.success("Successfully Executed");
    return response.data;
  } catch (error) {
    return toast.error(error?.response?.data?.error);
  }
};

export const fetchDataPermissionThunk = async (data) => {
  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }

    const response = await axiosInstance.post(
      `api/dxe/discovery/discover/details`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return toast.error(error?.response?.data?.error);
  }
};

export const fetchDataPermissonUnionThunk = async (data) => {
  try {
    const token = localStorage.getItem("idToken");
    if (!token) {
      toast.error("Unauthorized access. Please log in.");
      return;
    }

    const response = await axiosInstance.get(`api/uc/union/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return toast.error(error?.response?.data?.error);
  }
};

export const fetchPastRequestThunk = async (data) => {
  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access. Please log in.");
    }
    const response = await axiosInstance.get(`api/attributes/list`, {
      params: {
        pageNumber: data?.page,
        pageSize: data?.rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log("error", error.response.data.message);
  }
};

// For Demo Purpose

export const fetchIpiFicationPostThunk = async (data) => {
  try {
    const initialResponse = await axios.post(
      `http://3.238.201.69:7035/api/v1/ipification/generateUrl`,
      data,
      { validateStatus: null }
    );

    // if (initialResponse.status === 302 && initialResponse.headers.location) {
    //   const redirectResponse = await axios.get(initialResponse.headers.location);
    //   return redirectResponse.data;
    // }

    console.log("Redirecting to:", initialResponse.data);

    return initialResponse.data;
  } catch (error) {
    return toast.error(error?.response?.data?.error);
  }
};

export const fetchIpiFicationGetThunk = async (data) => {
  console.log("dataaa---", data);
  try {
    const response = await axios.get(
      `http://3.238.201.69:7035/api/v1/ipification/status/${data.mobileNumber}`
    );

    return response.data;
  } catch (error) {
    return toast.error(error?.response?.data?.error);
  }
};

export const fetchIpiFicationRespPostThunk = async (data) => {
  try {
    const response = await axios.get(`${data?.redirectionUrl}`, data?.urlData);

    console.log("resp-----", response);
    return response.data;
  } catch (error) {
    return toast.error(error?.response?.data?.error);
  }
};

export const UploadCreateTemplateHeader = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("idToken");
    if (!token) {
      toast.error("Unauthorized access.");
      return;
    }

    // const response = await axios.post(
    //   "https://api.dxe.whilter.ai/api/cm/minio/file",
    //   formData,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );

    const response = await axiosInstance.post(`api/cm/minio/file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )

    toast.success("File uploaded successfully!");

    dispatch({
      type: "UPLOAD_SUCCESS", // or whatever action type you need
      payload: response.data,
    });
  } catch (error) {
    return toast.error(error?.response?.data?.error);
  }
};


export const fetchTemplateListThunk = async (page) => {

  // console.log("page----", page);

  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access. Please log in.");
    }
    // const response = await axiosInstance.get(`api/template/template/list`);
    const response = await axiosInstance.get(`/api/com/`, {
      params: {
        page: page?.page,
        size: page?.rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );

    console.log("resp", response.data)
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log("error", error.response.data.message);
  }
}


export const SearchTeamsDetailsThunk = async (data) => {
  try {
    const token = localStorage.getItem("idToken");
    if (!token) {
      toast.error("Unauthorized access. Please log in.");
      return;
    }

    // const response = await axiosInstance.get("/api/com/dynamic/search", {
    //   params,  
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });



    const response = await axiosInstance.post(`api/com/dynamic/search?page=${data.page}&size=${data.size}`, data.data, {})

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred.");
  }
};

export const templateListSearchThunk = async (data) => {

  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access. Please log in.");
    }

    const response = await axiosInstance.get(
      `api/template/template`,
      {
        params: {
          name: data
        },
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
      }
    );
    return response.data;

  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export const TemplateListDeleteThunk = async (id) => {
  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }

    const response = await axiosInstance.delete(`api/com/${id}`, {
    });

    return response.data;
  } catch (error) {
    return toast.error(error?.response?.data?.message);
  }
};


export const createTemplateThunk = async (data) => {
  try {

    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }

    const response = await axiosInstance.post(`api/com/create/whatsapp`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return response.data
  } catch (error) {
    
    if (error?.status === 409) {
      return toast.error("Template with the same name already exists!");
  }
     toast.error(error.response.data.error);
     throw error?.status
  }

}

export const createTemplateSMSThunk = async (data) => {
  try {

    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }

    const response = await axiosInstance.post(`api/com/create`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return toast.error(error.response.data.message);
  }
};

export const fetchCreateContentThunk = async (data) => {

  try {
    const token = localStorage.getItem("idToken");

    if (!token) {
      toast.error("Unauthorized access.");
    }

    const response = await axiosInstance.post(`api/cm/content/create`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("respContent",response)

    return response.data

  } catch (error) {
    console.log("errorContent",error)
    return toast.error(error?.message);
  }
}

export const SearchCampaignListThunk = async (data) => {
  try {
    const token = localStorage.getItem("idToken");
    if (!token) {
      toast.error("Unauthorized access. Please log in.");
      return;
    }


    const response = await axiosInstance.post(`api/cm/campaign/search?page=${data.page}&size=${data.size}`, data.data, {})

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred.");
  }
};


export const fetchContentListMediaThunk = async (data) => {

  try {
    const token = localStorage.getItem("idToken");
    if (!token) {
      toast.error("Unauthorized access. Please log in.");
      return;
    }

    const response = await axiosInstance.post(`api/cm/content/dynamic/search?page=${data.page}&size=${data.size}`, data.data, {})

    return response.data;


  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred.");
  }
}




