import { Autocomplete, Avatar, Box, Button, Card, CircularProgress, Grid2, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../components/CustomBreadcrumb/CustomBreadcrumb';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import HideImageIcon from '@mui/icons-material/HideImage';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import { useOutletContext } from 'react-router-dom';
import MobileOTP from './Components/MobileOTP';
import AttributeTable from './AttributeTable';
import { fetchAttributesCategorySlice, fetchAudienceLanguageSlice, fetchBusinessCategorySlice, fetchCountryRegionSlice, fetchProfileSlice, mobileOtpSendSlice } from '../../redux/superAdmin/superAdmin.slice';
import { updateConsumerSlice } from '../../redux/superAdmin/superAdmin.slice';
import axiosInstance from '../../components/utils/axiosInstance';
import { toast, ToastContainer } from 'react-toastify';
import VerifiedIcon from '@mui/icons-material/Verified';


const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const businessCategoryOptions = [
    {
        label: 'Retail',
        value: 'retail'
    },
    {
        label: 'Tech',
        value: 'tech'
    },
];

const languageOptions = [
    {
        label: 'English',
        value: 'english'
    },
    {
        label: 'Spanish',
        value: 'spanish'
    },

];

const countryOptions = [
    {
        label: 'India',
        value: 'India'
    },
    {
        label: 'Indonasia',
        value: 'indonasia'
    },
    {
        label: 'Us',
        value: 'us'
    },
]

const EditConsumer = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dataGroup = useOutletContext();
    const [profileImage, setProfileImage] = useState(null);
    const [mobileNo, setMobileNo] = useState('');
    const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpSentVerified, setOtpVerified] = useState(false);
    const { attributeCategoryList, mobileOTPLoader, countryList, businessCategoryList, audienceLanguageList } = useSelector(
        (state) => state.superAdmin
    );
    useEffect(() => {
        dispatch(fetchProfileSlice())
    }, [])

    const { profileData } = useSelector(state => state.superAdmin);
    var loginData = useSelector(state => state.login.token)
    const cognitoGroups = loginData["groups"];

    const breadcrumb = [
        {
            title: 'Edit Profile',
            href: '',
        },
    ];

    const initialValues = {
        brandName: '',
        website: '',
        shortDesc: '',
        aboutBrand: '',
        monthlyActiveUsers: '',
        businessCategory: '',
        audienceRegion: [],
        countryRegion: [],
        audienceLanguage: [],
        gstNumber: '',
        directorName: '',
        companyAddress: '',
        companyName: '',
        businessName: '',
        mobileNumber: '',
        businessAccountId: '',
        token: '',
        whatsappNumber: '',
        phoneNumberId: '',
        smsSenderId: '',

    }


    const validationSchema = Yup.object().shape({
        brandName: Yup.string()
            .required('Brand is required'),
        businessCategory: Yup.string()
            .required('Business Category is required'),
        audienceLanguage: Yup.array()
            .of(Yup.string()) // Validates that each element in the array is a string
            .min(1, 'Audience Language is required'), // Ensures at least one value is selected
        audienceRegion: Yup.array()
            .of(Yup.string())
            .min(1, 'Audience Location is required'),
        mobileNumber: Yup.string()
            .matches(/^\d{10}$/, 'Mobile number must be 10 digits')
            .required('Mobile number is required'),
        companyName: Yup.string()
            .required('Company name is required'),
        monthlyActiveUsers: Yup.string()
            .matches(/^\d+$/, 'Only numeric values are allowed for monthly active users'),
        website: Yup.string()
            .matches(
                /^(https:\/\/|www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/\S*)?$/,
                'Website must start with "https://" or "www." and be a valid URL'
            ),
        gstNumber: Yup.string()
            .matches(
                /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/,
                'Invalid GST number format. Please enter a valid GSTIN.'
            )
    });
    // const [isProfileLoaded, setIsProfileLoaded] = useState(false);

    useEffect(() => {
        if (profileData && profileData.success) {
            const { user, brand, company, channelDetails } = profileData;

            // Map the API response to initial form values
            formik.setValues({
                brandName: brand?.brandName || '',
                website: brand?.website || '',
                brandId: brand?.brandId || null,
                aboutBrand: brand?.aboutBrand || '',
                monthlyActiveUsers: brand?.monthlyActiveUsers || '',
                businessCategory: brand?.businessCategory || '',
                audienceRegion: brand?.audienceRegion || [],
                countryRegion: brand?.countryRegion || [],
                audienceLanguage: brand?.audienceLanguage || [],
                gstNumber: company?.gstNumber || '',
                directorName: company?.directorNames || '',
                companyName: company?.companyName || '',
                companyAddress: company?.companyAddress || '',
                businessName: company?.businessName || '',
                id: company?.id,
                mobileNumber: company?.mobileNumber || '',
                whatsappNumber: channelDetails?.whatsappNumber || '',
                channelId: channelDetails?.channelId || '',
                phoneNumberId: channelDetails?.phoneNumberId || '',
                smsSenderId: channelDetails?.smsSenderId || '',
                token: channelDetails?.token || '',
                businessAccountId: channelDetails?.businessAccountId || ''





            });

            // Set the profile image URL
            setProfileImage(brand?.logoUrl || null);

            if (company?.mobileNumber) {
                setOtpVerified(true);
            }

            // setIsProfileLoaded(true); // Indicate that the profile is loaded
        }
    }, [profileData]);

    useEffect(() => {
        dispatch(fetchCountryRegionSlice());
        dispatch(fetchBusinessCategorySlice());
        dispatch(fetchAudienceLanguageSlice());
    }, [])


    const handleImageChange = async (event) => {

        const file = event.target.files[0];

        // if (file) {
        //     const imageUrl = URL.createObjectURL(file);
        //     setProfileImage(imageUrl);
        // }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('title', 'SampleFile');

        try {
            const token = localStorage.getItem('idToken');
            if (!token) {
                toast.error('Unauthorized access.');
                return;
            }

            const response = await axiosInstance.post(
                '/api/profile/minio/file',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            toast.success('File uploaded successfully!');
            console.log('API Response:', response.data);
            setProfileImage(response.data)
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Failed to upload file. Please try again.');
        }

    };

    const handleRemoveImage = () => {
        setProfileImage(null);
    };

    const handleMobileChange = async (event) => {
        setOtpVerified(false);
        const value = event.target.value
        setMobileNo(value)
        formik.setFieldValue('mobileNumber', value);

        if (value.length === 10) {

            const result = await dispatch(mobileOtpSendSlice(value)).unwrap();

            if (result) {
                setOtp(result);
                setIsOTPModalOpen(true);
            }
        }
    }

    useEffect(() => {
        dispatch(fetchAttributesCategorySlice());
    }, [])

    const handleClose = () => {
        setIsOTPModalOpen(false);
    }

    const handleSubmit = async (values) => {



        // Base data structure
        const data = {
            brand: {
                brandName: values?.brandName,
                logoUrl: profileImage ? profileImage?.url : "",
                website: values?.website,
                aboutBrand: values?.aboutBrand,
                monthlyActiveUsers: values?.monthlyActiveUsers,
                businessCategory: values?.businessCategory,
                countryRegion: values?.countryRegion,
                audienceRegion: values?.audienceRegion,
                audienceLanguage: values?.audienceLanguage,
                "brandId": values?.brandId || null

            },
            company: {
                gstNumber: values?.gstNumber,
                directorNames: values?.directorName,
                companyName: values?.companyName,
                companyAddress: values?.companyAddress,
                businessName: values?.businessName,
                mobileNumber: values?.mobileNumber,
                "id": values?.id || null
            },
        };

        // Add extra fields based on cognitoGroups
        if (cognitoGroups.length > 1 || cognitoGroups[0] === "DataUser") {
            data.channelDetails = {
                whatsappNumber: values?.whatsappNumber,
                businessAccountId: values?.businessAccountId,
                token: values?.token,
                phoneNumberId: values?.phoneNumberId,
                smsSenderId: values?.smsSenderId,
                whatsappVerified: false,
                "channelId": values?.channelId || null
            };
        }

        // Dispatch the action


        // if (result) {
        //     formik.resetForm();
        // }

        try {
            const result = await dispatch(updateConsumerSlice(data)).unwrap();

            console.log('resultEditConsumer', result)
            if (result?.success === true) {

                if (cognitoGroups[0] === "DataProvider") {
                    navigate("/data-request");
                } else {
                    navigate("/dashboard");
                }

                dispatch(fetchProfileSlice())
            } else {
                console.error("Unexpected status:", result?.status);
            }
        } catch (error) {
            console.error("Error during admin signup:", error);
        }

        // Reset the form
        // resetForm();
    };


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <ToastContainer />
                <CustomBreadcrumb breadcrumb={breadcrumb} />

                <Grid2 container mt={3}>

                    <Grid2 size={{ xs: 12 }}>
                        <Typography
                            sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
                        >
                            Edit Profile
                        </Typography>
                    </Grid2>
                </Grid2>

                <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>

                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12 }} sx={{ display: 'flex', alignItems: "flex-start", }}>
                            <Box textAlign={'center'}>
                                <Avatar
                                    src={profileImage?.url ? profileImage?.url : profileImage}
                                    alt="Profile"
                                    sx={{
                                        width: 120,
                                        height: 120,
                                        margin: 'auto',
                                        border: '2px solid #ccc',
                                    }}
                                />
                                <Box sx={{ position: 'relative', marginTop: '-15px' }}>
                                    {profileImage ? (
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            onClick={handleRemoveImage}
                                            component="span"
                                            sx={{ backgroundColor: '#f5f5f5', boxShadow: 1, marginTop: '20px' }}
                                        >
                                            <HideImageIcon />
                                        </IconButton>
                                    ) : (
                                        <>
                                            <label htmlFor="upload-button">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    sx={{ backgroundColor: '#f5f5f5', boxShadow: 1, marginTop: '20px' }}
                                                >
                                                    <PhotoCamera />
                                                </IconButton>
                                            </label>
                                            <input
                                                id="upload-button"
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleImageChange}
                                            />
                                        </>
                                    )}
                                </Box>
                            </Box>

                            {/* <Box className="showDetails" sx={{ marginLeft: 5 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
                                <Typography variant="body1" color="textPrimary">
                                    Name: Sourabh Chaprana
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    Email: sourabh@racloop.com
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    Display Name: Kumar Sharma
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    Type: Dc
                                </Typography>
                            </Box>
                        </Box> */}
                        </Grid2>

                        <Grid2 size={{ xs: 12 }}>
                            <Typography
                                sx={{ color: "#445A68", fontSize: "24px", fontWeight: "500" }}
                            >
                                Brand Details
                            </Typography>
                        </Grid2>

                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Brand Name{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="brandName"
                                value={formik.values.brandName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.brandName && Boolean(formik.errors.brandName)}
                                helperText={formik.touched.brandName && formik.errors.brandName}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Website{" "}
                            </Typography>

                            <TextField
                                name="website"
                                value={formik.values.website}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.website && Boolean(formik.errors.website)}
                                helperText={formik.touched.website && formik.errors.website}
                            />
                        </Grid2>


                        <Grid2 size={{ xs: 12 }}>
                            <Typography className={classes.label}>
                                About the Brand{" "}
                            </Typography>

                            <TextField
                                name="aboutBrand"
                                value={formik.values.aboutBrand}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.aboutBrand && Boolean(formik.errors.aboutBrand)}
                                helperText={formik.touched.aboutBrand && formik.errors.aboutBrand}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Monthly Active Users{" "}
                            </Typography>

                            <TextField
                                name="monthlyActiveUsers"
                                value={formik.values.monthlyActiveUsers}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.monthlyActiveUsers && Boolean(formik.errors.monthlyActiveUsers)}
                                helperText={formik.touched.monthlyActiveUsers && formik.errors.monthlyActiveUsers}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Business Category{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                name="businessCategory"
                                options={businessCategoryList}
                                getOptionLabel={(option) =>
                                    option?.label ? option.label : ""
                                }
                                value={
                                    businessCategoryList.find(
                                        (option) => option.value === formik.values.businessCategory
                                    ) || null
                                }
                                onChange={(e, value) => {
                                    formik.setFieldValue("businessCategory", value ? value.value : "");
                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please Select Business Category"
                                        error={
                                            formik.touched.businessCategory && Boolean(formik.errors.businessCategory)
                                        }
                                        helperText={
                                            formik.touched.businessCategory && formik.errors.businessCategory
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Audience Location{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                multiple
                                name="audienceRegion"
                                options={countryList}
                                getOptionLabel={(option) =>
                                    option?.label ? option.label : ""
                                }
                                value={
                                    formik.values.audienceRegion
                                        ? countryList.filter((option) =>
                                            formik.values.audienceRegion.includes(option.value)
                                        )
                                        : []
                                }
                                onChange={(event, value) => {
                                    formik.setFieldValue(
                                        "audienceRegion",
                                        value.map((item) => item.value)
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={formik.values.audienceRegion.length > 0 ? '' : 'Please Select Audience Location'}
                                        error={
                                            formik.touched.audienceRegion && Boolean(formik.errors.audienceRegion)
                                        }
                                        helperText={
                                            formik.touched.audienceRegion && formik.errors.audienceRegion
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Country Region
                            </Typography>
                            <Autocomplete
                                multiple
                                options={countryList}
                                className={classes.selectField}
                                getOptionLabel={(option) => option.label}
                                value={
                                    formik.values.countryRegion
                                        ? countryList.filter((option) =>
                                            formik.values.countryRegion.includes(option.value)
                                        )
                                        : []
                                }
                                onChange={(event, value) => {
                                    formik.setFieldValue(
                                        "countryRegion",
                                        value.map((item) => item.value)
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={formik.values.countryRegion.length > 0 ? '' : 'Please Select Country Region'}
                                        error={
                                            formik.touched.countryRegion &&
                                            Boolean(formik.errors.countryRegion)
                                        }
                                        helperText={
                                            formik.touched.countryRegion && formik.errors.countryRegion
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Audience Language <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                multiple
                                options={audienceLanguageList}
                                className={classes.selectField}
                                getOptionLabel={(option) => option.label || ""}
                                value={
                                    formik.values.audienceLanguage
                                        ? audienceLanguageList.filter((option) =>
                                            formik.values.audienceLanguage.includes(option.value)
                                        )
                                        : []
                                }
                                onChange={(event, value) => {
                                    formik.setFieldValue(
                                        "audienceLanguage",
                                        value.map((item) => item.value)
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={formik.values.audienceLanguage.length > 0 ? '' : 'Please Select Audience Language'}
                                        error={
                                            formik.touched.audienceLanguage &&
                                            Boolean(formik.errors.audienceLanguage)
                                        }
                                        helperText={
                                            formik.touched.audienceLanguage &&
                                            formik.errors.audienceLanguage
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>

                    </Grid2>
                </Card>

                <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography
                                sx={{ color: "#445A68", fontSize: "24px", fontWeight: "500" }}
                            >
                                Company Information
                            </Typography>
                        </Grid2>

                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                GST Number{" "}
                            </Typography>

                            <TextField
                                name="gstNumber"
                                value={formik.values.gstNumber} a
                                onChange={(e) => {
                                    const uppercasedValue = e.target.value.toUpperCase();
                                    formik.setFieldValue(
                                        "gstNumber",
                                        uppercasedValue.replace(/[^0-9A-Z]/g, "")
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.gstNumber && Boolean(formik.errors.gstNumber)}
                                helperText={formik.touched.gstNumber && formik.errors.gstNumber}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Company Director's Name{" "}
                            </Typography>

                            <TextField
                                name="directorName"
                                value={formik.values.directorName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.directorName && Boolean(formik.errors.directorName)}
                                helperText={formik.touched.directorName && formik.errors.directorName}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography className={classes.label}>
                                Company Address {" "}
                            </Typography>

                            <TextField
                                name="companyAddress"
                                value={formik.values.companyAddress}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.companyAddress && Boolean(formik.errors.companyAddress)}
                                helperText={formik.touched.companyAddress && formik.errors.companyAddress}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Company Name {" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                helperText={formik.touched.companyName && formik.errors.companyName}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Business Name{" "}
                            </Typography>

                            <TextField
                                name="businessName"
                                value={formik.values.businessName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                                helperText={formik.touched.businessName && formik.errors.businessName}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Mobile Number{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="mobileNumber"
                                value={formik.values.mobileNumber}
                                onChange={handleMobileChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                onInput={(e) => {
                                    const inputValue = e.target.value;
                                    const numericInput = inputValue.replace(/\D/g, '');
                                    const truncatedInput = numericInput.slice(0, 10);
                                    e.target.value = truncatedInput;
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <VerifiedIcon sx={{ fill: otpSentVerified ? "#008000" : "" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Grid2>
                    </Grid2>
                </Card>


                {(cognitoGroups.length > 1 || dataGroup?.dataGroup == "DataUser") && (
                    <Card
                        sx={{
                            mt: 5,
                            p: 3,
                            borderRadius: "15px",
                            boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                        }}
                    >
                        <Typography
                            sx={{ color: "#445A68", fontSize: "24px", fontWeight: "500", marginBottom: '50px' }}
                        >
                            Channel
                        </Typography>



                        <Typography sx={{ color: "#445A68", fontSize: "18px", fontWeight: "500", marginBottom: '20px' }}> Whatsapp  </Typography>
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 6 }}>
                                <Typography className={classes.label}>
                                    Business Account ID
                                </Typography>
                                <TextField
                                    name="businessAccountId"
                                    value={formik.values.businessAccountId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={classes.textField}
                                    error={formik.touched.businessAccountId && Boolean(formik.errors.businessAccountId)}
                                    helperText={formik.touched.businessAccountId && formik.errors.businessAccountId}
                                />
                            </Grid2>

                            {/* Token */}
                            <Grid2 size={{ xs: 6 }}>
                                <Typography className={classes.label}>
                                    Token
                                </Typography>
                                <TextField
                                    name="token"
                                    value={formik.values.token}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={classes.textField}
                                    error={formik.touched.token && Boolean(formik.errors.token)}
                                    helperText={formik.touched.token && formik.errors.token}
                                />
                            </Grid2>

                            {/* Number */}
                            <Grid2 size={{ xs: 6 }}>
                                <Typography className={classes.label}>
                                    Whatsapp Number
                                </Typography>
                                <TextField
                                    name="whatsappNumber"
                                    value={formik.values.whatsappNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={classes.textField}
                                    error={formik.touched.whatsappNumber && Boolean(formik.errors.whatsappNumber)}
                                    helperText={formik.touched.whatsappNumber && formik.errors.whatsappNumber}
                                />
                            </Grid2>

                            {/* Phone Number ID */}
                            <Grid2 size={{ xs: 6 }}>
                                <Typography className={classes.label}>
                                    Phone Number ID
                                </Typography>
                                <TextField
                                    name="phoneNumberId"
                                    value={formik.values.phoneNumberId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={classes.textField}
                                    error={formik.touched.phoneNumberId && Boolean(formik.errors.phoneNumberId)}
                                    helperText={formik.touched.phoneNumberId && formik.errors.phoneNumberId}
                                />
                            </Grid2>


                        </Grid2>

                        <Typography sx={{ color: "#445A68", fontSize: "18px", fontWeight: "500", margin: '30px 0px', marginBottom: '10px' }}> SMS  </Typography>
                        <Grid2 container spacing={2}>

                            {/* Phone Number ID */}
                            <Grid2 size={{ xs: 6 }}>
                                <Typography className={classes.label}>
                                    SMS Sender  ID
                                </Typography>
                                <TextField
                                    name="smsSenderId"
                                    value={formik.values.smsSenderId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={classes.textField}
                                    error={formik.touched.smsSenderId && Boolean(formik.errors.smsSenderId)}
                                    helperText={formik.touched.smsSenderId && formik.errors.smsSenderId}
                                />
                            </Grid2>


                        </Grid2>
                    </Card>
                )}



                {(cognitoGroups.length > 1 || dataGroup?.dataGroup === "DataProvider") &&
                    <AttributeTable attributeCategoryList={attributeCategoryList} />
                }

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                        sx={{
                            borderRadius: '10px',
                            color: '#FFFFFF !important',
                            fontSize: '18px !important',
                            background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
                            padding: '8px 16px !important',
                            textTransform: 'capitalize'
                        }}
                        type='submit'
                    >
                        Proceed
                    </Button>
                </Box>
            </Box>

            <MobileOTP
                open={isOTPModalOpen}
                otp={otp}
                setOtp={setOtp}
                otpSentVerified={otpSentVerified}
                setOtpVerified={setOtpVerified}
                handleClose={handleClose}
                mobileNumber={mobileNo}
            />
            {
                mobileOTPLoader ?
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    : ''
            }
        </form>
    )
}

export default EditConsumer


