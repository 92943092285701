import { Box, Button, Card, Grid2, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CustomBreadcrumb from '../../../../components/CustomBreadcrumb/CustomBreadcrumb';
import { ContentCopy, Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';

const RegisterDetails = () => {

    // const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [copied, setCopied] = useState(false);
    const { formData, isLoading, data, error } = useSelector(
        (state) => state.superAdmin
    );

    const userData = formData?.user

    const breadcrumb = [
        {
            title: 'User Management',
            href: '/user-management',
        },
        {
            title: 'Register',
            href: '/register',
        },
        {
            title: 'Registered User Details',
            href: '',
        },
    ];

    const handleCopyPassword = () => {
        const password = userData.password;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(password)
                .then(() => {
                    setCopied(true);
                    toast.success('Password Copied');
                    setTimeout(() => setCopied(false), 2000);
                })
                .catch((error) => {
                    toast.error('Failed to copy password');
                });
        } else {
            // Fallback for older browsers or unsupported environments
            const textarea = document.createElement('textarea');
            textarea.value = password;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setCopied(true);
            toast.success('Password Copied');
            setTimeout(() => setCopied(false), 2000);
        }
    };

    console.log("Data-----", userData?.groups[0])

    return (
        <Box>
            <CustomBreadcrumb breadcrumb={breadcrumb} />

            <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center', marginTop: '24px' }}>

                <Typography sx={{ fontSize: '22px', color: '#445A68', fontWeight: '600' }}>
                    Registered User Details
                </Typography>
            </Box>

            <Card
                sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}
            >
                <Grid2 container spacing={3}>
                    {[
                        { label: 'First Name', value: userData.firstName },
                        { label: 'Last Name', value: userData.lastName },
                        { label: 'Display Name', value: userData.displayName },
                        {
                            label: 'Password',
                            value: (
                                <Box display="flex" alignItems="center">
                                    {showPassword ? userData?.password : '•'.repeat(userData?.password?.length)}
                                    <Tooltip title={t('Copy Password')}>
                                        <IconButton onClick={handleCopyPassword} size="small" sx={{ ml: 1 }}>
                                            <ContentCopy fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={showPassword ? t('Hide Password') : t('Show Password')}>
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            size="small"
                                            sx={{ ml: 1 }}
                                        >
                                            {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ),
                        },
                        { label: 'Business Email', value: userData?.email },
                        {
                            label: 'Type',
                            value:
                                userData?.partnerType === 'BRAND'
                                    ? 'Brand'
                                    : userData?.partnerType === 'AGENCY'
                                        ? 'Agency'
                                        : 'Unknown',
                        },
                        {
                            label: 'Category',
                            value:
                                userData?.groups[0] === 'DataProvider'
                                    ? 'Data Provider'
                                    : userData?.groups[0] === 'DataUser'
                                        ? 'Data Consumer'
                                        : userData?.groups[0] === 'Both'
                                            ? 'Data Provider and Data Consumer (Both)'
                                            : 'Unknown',
                        },
                        { label: 'Organization Name', value: userData?.orgName },
                    ].map((detail, index) => (
                        <Grid2 size={{ xs: 12, md: 6 }} key={index}>
                            <Typography variant="subtitle1" color="textSecondary">
                                {t(detail?.label)}
                            </Typography>
                            <Typography variant="h6" fontWeight="bold" color="#445A68">
                                {detail?.value}
                            </Typography>
                        </Grid2>
                    ))}
                </Grid2>
            </Card>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "15px",
                    paddingY: "20px",
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: "40px",
                        padding: "16px 24px",
                        fontSize: "18px",
                        backgroundColor: "#445A68",
                        color: "#FFFFFF",
                        fontWeight: "600",
                        border: "1px solid rgba(68, 90, 104, 1)",
                    }}
                    onClick={() => {navigate('/user-management')}}
                >
                    Back To List
                </Button>
                <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                        borderRadius: "40px",
                        padding: "16px 24px",
                        color: "#FFFFFF",
                        background: "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                        fontSize: "18px",
                        fontWeight: "600",
                        border: "1px solid #13BECF",
                    }}
                    onClick={() => {navigate('/register')}}
                >
                    Back To Register
                </Button>
            </Box>
        </Box>
    )
}

export default RegisterDetails