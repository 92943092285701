import { Autocomplete, Box, Button, Card, Grid2, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/CustomBreadcrumb'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Delete, Add } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { createAttributeSlice, fetchAttributesCategorySlice, fetchAttributeTypeSlice, fetchMetaTypeSlice } from '../../../redux/superAdmin/superAdmin.slice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));
const statusStyles = {
    active: {
        backgroundColor: "#e0f7e9",
        color: "#34a853",
    },
    offline: {
        backgroundColor: "#f5f5f5",
        color: "#9e9e9e",
    },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));


const AddAttributes = () => {

    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { attributeCategoryList, metaTypeList, attributeType, loading } = useSelector(
        (state) => state.superAdmin
    );

    const breadcrumb = [

        {
            title: 'Attributes',
            href: '/attributes',
        },
        {
            title: 'Add Attributes',
            href: '',
        },
    ];

    const initialValues = {
        possibleValues: [{ value: "" }],
        attributeName: '',
        type: '',
        metaType: '',
        attributeCategory: ''
    }

    const validationSchema = Yup.object().shape({
        attributeName: Yup.string().required('Name is required'),
        type: Yup.string().required('Type is required'),
        metaType: Yup.object().required('Meta Type is required'),
        attributeCategory: Yup.string().required('Attribute Category is required'),
        // possibleValues: Yup.array()
        // .of(
        //   Yup.object().shape({
        //     value: Yup.string()
        //       .required("Value is required")
        //   })
        // )
        // .min(1, "At least one value is required"),
    })

    // const validationSchema = Yup.object({
    //     attributeName: Yup.string().required('Attribute name is required'),
    //     possibleValues: Yup.array()
    //         .of(
    //             Yup.object({
    //                 value: Yup.string()
    //                     .required("Value is required")
    //                     .min(2, "Value must be at least 2 characters"),
    //             })
    //         )
    //         .when('metaType', (metaType, schema) => {
    //             if (metaType?.value === 'Distinct') {
    //                 return schema.min(1, "At least one possible value is required");
    //             }
    //             return schema.notRequired();
    //         }),
    //     metaType: Yup.object().nullable().required('Meta Type is required'),
    // });


    const addPossibleFields = () => {

        formik.setFieldValue("possibleValues", [
            ...formik.values.possibleValues,
            { value: "" }
        ])
    }

    const removeValueFields = (index) => {

        const newValues = formik.values.possibleValues.filter((_, i) => i !== index);
        formik.setFieldValue("possibleValues", newValues);
    }

    useEffect(() => {

        dispatch(fetchAttributesCategorySlice());
        dispatch(fetchMetaTypeSlice());
        dispatch(fetchAttributeTypeSlice());
    }, [])


    // const handleSubmit = async (values) => {

    //     const payload = {
    //         code: "ATTR008",
    //         name: values.attributeName,
    //         attributeTypeId: values.type,
    //         active: true,
    //         available: true,
    //         deleted: false,
    //         attributeCategoryId: values.attributeCategory,
    //         attributeMetaTypeId: values.metaType?.id,
    //     };

    //     if (values.metaType?.value === 'Distinct') {
    //         payload.possibleValues = values.possibleValues;
    //     }

    //     try {
    //         const result = await dispatch(createAttributeSlice(payload)).unwrap();

    //         console.log("attribute result---",result);

    //         if (result) {
    //             navigate('/attributes')
    //         }
    //     } catch (error) {

    //     }
    // }

    const handleSubmit = async (values) => {
        const payload = {
            code: "ATTR008",
            name: values.attributeName,
            attributeTypeId: values.type,
            active: true,
            available: true,
            deleted: false,
            attributeCategoryId: values.attributeCategory,
            attributeMetaTypeId: values.metaType?.id,
        };
    
        if (values.metaType?.value === 'Distinct') {
            payload.possibleValues = values.possibleValues;
        }
    
        try {
            const result = await dispatch(createAttributeSlice(payload)).unwrap();

            console.log("resultAttri---",result);
            
            if (result.status === 200) {
                toast.success("Attribute created successfully!");
                navigate('/attributes');
            }

        } catch (error) {            
            console.error("API request failed:", error);
        }
    };
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    console.log("Formik---", formik)

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <CustomBreadcrumb breadcrumb={breadcrumb} />

                <Grid2 container mt={3}>
                    <Grid2 size={{ xs: 12, md: 8 }}>
                        <Typography
                            sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
                        >
                            Attributes
                        </Typography>
                    </Grid2>
                </Grid2>

                <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Name{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="attributeName"
                                value={formik.values.attributeName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.attributeName && Boolean(formik.errors.attributeName)}
                                helperText={formik.touched.attributeName && formik.errors.attributeName}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Type{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                name="type"
                                options={attributeType || []}
                                getOptionLabel={(option) =>
                                    option?.label ? option?.label : ""
                                }
                                onChange={(e, value) => {
                                    console.log("Attribute Type Value---", value.id)
                                    formik.setFieldValue("type", value ? value?.id : "");
                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please Select Type"
                                        error={
                                            formik.touched.type && Boolean(formik.errors.type)
                                        }
                                        helperText={
                                            formik.touched.type && formik.errors.type
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Attribute Category{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                name="attributeCategory"
                                options={attributeCategoryList || []}
                                getOptionLabel={(option) =>
                                    option?.label ? option?.label : ""
                                }
                                onChange={(e, value) => {
                                    formik.setFieldValue("attributeCategory", value ? value?.id : "");
                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please Select Audience Language"
                                        error={
                                            formik.touched.attributeCategory && Boolean(formik.errors.attributeCategory)
                                        }
                                        helperText={
                                            formik.touched.attributeCategory && formik.errors.attributeCategory
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Meta Type{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                name="metaType"
                                options={metaTypeList || []}
                                getOptionLabel={(option) =>
                                    option?.label ? option?.label : ""
                                }
                                onChange={(e, value) => {
                                    formik.setFieldValue("metaType", value ? value : "");
                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please Select Audience Language"
                                        error={
                                            formik.touched.metaType && Boolean(formik.errors.metaType)
                                        }
                                        helperText={
                                            formik.touched.metaType && formik.errors.metaType
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>
                        {formik.values.metaType?.value === 'Distinct' && (

                            <Grid2 size={{ xs: 6 }}>
                                <TableContainer
                                    component={Paper}
                                    sx={{ mt: 2, border: "1px solid #D4D4D4", borderRadius: "12px" }}
                                >
                                    <Table aria-label="campaign table">
                                        <TableHead sx={{ background: "#F1F1F1" }}>
                                            <TableRow>
                                                <TableCell>
                                                    <BoxContainer>
                                                        <TableHeadTitle>Possible Values</TableHeadTitle>
                                                    </BoxContainer>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formik.values.possibleValues.map((item, index) => (
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <Grid2
                                                            sx={{ paddingY: '8px' }}
                                                            container
                                                            size={{ xs: 12 }}
                                                            alignItems="center"
                                                            spacing={2}
                                                            key={index}
                                                        >
                                                            <Grid2 size={{ xs: 11 }}>

                                                                <Typography className={classes.label}>
                                                                    Possible Value {index + 1}{" "}
                                                                    <span style={{ color: "#FF0000" }}>*</span>
                                                                </Typography>

                                                                <TextField
                                                                    name={`possibleValues[${index}].value`}
                                                                    value={item.value}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    error={
                                                                        formik.touched.possibleValues?.[index]?.value &&
                                                                        Boolean(formik.errors.possibleValues?.[index]?.value)
                                                                    }
                                                                    helperText={
                                                                        formik.touched.possibleValues?.[index]?.value &&
                                                                        formik.errors.possibleValues?.[index]?.value
                                                                    }
                                                                    className={classes.textField}
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 1 }} marginTop={3}>
                                                                <IconButton
                                                                    color='error'
                                                                    disabled={formik.values.possibleValues.length == 1}
                                                                    onClick={() => { removeValueFields(index) }}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </Grid2>
                                                        </Grid2>
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid2 size={{ xs: 12 }} sx={{ marginTop: '16px', textAlign: "right" }} >
                                    <Button
                                        sx={{
                                            borderRadius: "10px",
                                            color: "#445A68",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            padding: '8px !important',
                                            border: "1px solid #445A68",
                                        }}
                                        variant='outlined'
                                        startIcon={<Add />}
                                        onClick={addPossibleFields}
                                    >
                                        Add Possible Values
                                    </Button>
                                </Grid2>
                            </Grid2>
                        )}
                    </Grid2>
                </Card>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                    sx={{
                        borderRadius: '10px',
                        color: '#FFFFFF !important',
                        fontSize: '18px !important',
                        background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
                        padding: '8px 16px !important',
                        textTransform: 'capitalize'
                    }}
                    type='submit'
                >
                    Submit
                </Button>
            </Box>
        </form>
    )
}

export default AddAttributes