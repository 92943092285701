import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, TextField, Typography, Tabs, Tab, Grid } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import { calculateAudienceSlice, fetchCostPerMilleSlice } from "../../../redux/stepperSlice/stepper.slice";
import useDebounce from "../../../components/UseDebounce/useDebounce";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const BudgetManagement = ({
    classes,
    formValues,
    setFormValues,
    exceeded,
    setExceeded,
    errors,
    touched,
    setFieldValue,
    values,
    audienceData,
    t
}) => {

    const campaignResponse = audienceData?.createCampaignResponse;
    // const budgetResponse = audienceData?.budgetData?.campaignScheduleList;
    const budgetResponse = audienceData?.budgetData?.campaignScheduleList || [];

    const tabLabels = [
        { key: "oneDay", label: "1 Day", value: campaignResponse?.oneDay },
        { key: "sevenDay", label: `${campaignResponse?.sevenDay} Days`, value: campaignResponse?.sevenDay },
        { key: "thirtyDay", label: `${campaignResponse?.thirtyDay} Days`, value: campaignResponse?.thirtyDay },
    ].filter(tab => tab.value > 0);

    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState(true);
    const [costPerMille, setCostPerMille] = useState('');
    const [debouncedBudget, setDebouncedBudget] = useState({
        budget1Day: 0,
        budget7Days: 0,
        budget30Days: 0,
    });
    const dispatch = useDispatch();

    const debouncedValues = useDebounce(debouncedBudget, 1000);

    useEffect(() => {
        if (debouncedValues.budget1Day > 0) fetchImpressions(debouncedValues.budget1Day, 0);
        if (debouncedValues.budget7Days > 0) fetchImpressions(debouncedValues.budget7Days, 1);
        if (debouncedValues.budget30Days > 0) fetchImpressions(debouncedValues.budget30Days, 2);
    }, [debouncedValues]);

    useEffect(() => {

        const fetchCPM = async () => {

            const data = formValues?.channel

            const result = await dispatch(fetchCostPerMilleSlice(data)).unwrap();

            if (result) {
                setCostPerMille(result);
            }
        }

        fetchCPM();

    }, [])


    const handleChange = (event, newValue) => setValue(newValue);
    const handleAccordionChange = () => setExpanded((prev) => !prev);

    const fetchImpressions = async (budget, tab) => {
        const data = { budget, method: formValues?.channel };
        try {
            const result = await dispatch(calculateAudienceSlice(data)).unwrap();

            if (result) {
                const updatedImpressions = result.audience;
                setFormValues((prevValues) => {
                    const updatedFormValues = { ...prevValues };
                    if (tab === 0) {
                        updatedFormValues.impressions1Day = updatedImpressions;
                        updatedFormValues.budget1Day = updatedImpressions * costPerMille
                    }
                    else if (tab === 1) {
                        updatedFormValues.impressions7Days = updatedImpressions;
                        updatedFormValues.budget7Days = updatedImpressions * costPerMille
                    }
                    else if (tab === 2) {
                        updatedFormValues.impressions30Days = updatedImpressions;
                        updatedFormValues.budget30Days = updatedImpressions * costPerMille
                    }
                    return updatedFormValues;
                });
                setFieldValue(
                    tab === 0 ? "impressions1Day" : tab === 1 ? "impressions7Days" : "impressions30Days",
                    updatedImpressions
                );
                setFieldValue(
                    tab === 0 ? "budget1Day" : tab === 1 ? "budget7Days" : "budget30Days",
                    updatedImpressions * costPerMille
                );
            }
        } catch (error) {
            console.error("Error fetching impressions:", error);
        }
    };

    const handleBudgetChange = (newBudget, tab) => {
        setDebouncedBudget((prev) => {
            const updatedBudget = { ...prev };
            if (tab === 0) updatedBudget.budget1Day = newBudget;
            else if (tab === 1) updatedBudget.budget7Days = newBudget;
            else if (tab === 2) updatedBudget.budget30Days = newBudget;
            return updatedBudget;
        });
    };

    console.log("formValues", formValues);
    console.log("debouncedBudget", debouncedBudget);

    return (
        <Accordion sx={{ border: "1px solid #ccc", borderRadius: "10px", boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)", marginBottom: "25px" }} defaultExpanded>
            <AccordionSummary
                sx={{
                    minHeight: "65px ",
                    color: "#333333",
                    fontWeight: "500",
                    fontSize: "19px",
                    borderTopLeftRadius: "15px !important",
                    borderTopRightRadius: "15px !important",
                    borderRadius: "10px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    {!expanded ? (
                        <CheckCircleIcon sx={{ fill: "#13BECF", borderRadius: "50%", width: "25px", height: "25px" }} />
                    ) : (
                        <RadioButtonUncheckedIcon sx={{ fill: "#13BECF", borderRadius: "50%", width: "25px", height: "25px" }} />
                    )}
                    {t('stepper.audienceManager.budgetManagement')}
                </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: "0px 0px 16px" }}>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} variant="fullWidth" sx={{ width: "100%", ".MuiTabs-flexContainer": { justifyContent: "space-around" }, ".MuiTabs-indicator": { height: "5px", backgroundColor: "#13BECF" } }}>
                            {tabLabels.map((tab, index) => (
                                <Tab
                                    key={index}
                                    label={tab.label}
                                    sx={{
                                        flex: 1,
                                        "&.Mui-selected": {
                                            color: "#333333", fontWeight: "600", backgroundColor: "#F5F5F5"

                                        }
                                    }}
                                    {...a11yProps(index)}
                                />
                            ))}
                        </Tabs>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px 24px" }}>
                        <Typography className={classes.label}>
                            {t('stepper.audienceManager.totalBudget')}:{" "}
                            <span style={{ color: "#524f4f", fontWeight: "500" }}>
                                {formValues?.totalBudget}
                            </span>
                        </Typography>
                        <Typography className={classes.label}>
                            {t('campaignList.channel')}:{" "}
                            <span style={{ fontWeight: "500", color: "#524f4f" }}>
                                {formValues?.channel === "WHATSAPP" ? "Whatsapp" : formValues?.channel}
                            </span>
                        </Typography>
                        <Typography className={classes.label}>
                            CPM:{" "}{costPerMille}
                        </Typography>
                    </Box>

                    <CustomTabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                            {budgetResponse.length > 0 && (
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t('campaignList.startDate')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(budgetResponse[0]?.startDate)}
                                            readOnly
                                            className={classes.textField}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />

                                    </LocalizationProvider>
                                </Grid>
                            )}
                            {budgetResponse?.length > 0 && (
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t('campaignList.endDate')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(budgetResponse[0]?.endDate)}
                                            readOnly
                                            className={classes.textField}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Typography className={classes.label}>{t('stepper.audienceManager.budget')}</Typography>
                                <Field name="budget1Day">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            value={values.budget1Day}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                field.onChange(e);
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    budget1Day: newValue,
                                                }));
                                                handleBudgetChange(newValue, 0);
                                            }}
                                            className={classes.textField}
                                            error={exceeded}
                                            helperText={exceeded && errors.total}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>{t('stepper.audienceManager.estimatedImpressions')}</Typography>
                                <Field name="impressions1Day">
                                    {({ field }) => <TextField {...field} disabled className={classes.textField} />}
                                </Field>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                        <Grid container spacing={2}>
                            {budgetResponse?.length > 0 && (
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t('campaignList.startDate')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(budgetResponse[1]?.startDate)}
                                            readOnly
                                            className={classes.textField}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                            {budgetResponse?.length > 0 && (
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t('campaignList.endDate')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(budgetResponse[1]?.endDate)}
                                            readOnly
                                            className={classes.textField}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Typography className={classes.label}>{t('stepper.audienceManager.budget')}</Typography>
                                <Field name="budget7Days">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            value={values.budget7Days}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                field.onChange(e);
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    budget7Days: newValue,
                                                }));
                                                handleBudgetChange(newValue, 1);
                                            }}
                                            className={classes.textField}
                                            error={exceeded}
                                            helperText={exceeded && errors.total}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>{t('stepper.audienceManager.estimatedImpressions')}</Typography>
                                <Field name="impressions7Days">
                                    {({ field }) => <TextField {...field} disabled className={classes.textField} />}
                                </Field>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={2}>
                        <Grid container spacing={2}>
                            {budgetResponse?.length > 0 && (
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t('campaignList.startDate')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(budgetResponse[2]?.startDate)}
                                            readOnly
                                            className={classes.textField}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                            {budgetResponse?.length > 0 && (
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t('campaignList.endDate')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(budgetResponse[2]?.endDate)}
                                            readOnly
                                            className={classes.textField}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Typography className={classes.label}>{t('stepper.audienceManager.budget')}</Typography>
                                <Field name="budget30Days">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            value={values.budget30Days}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                field.onChange(e);
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    budget30Days: newValue,
                                                }));
                                                handleBudgetChange(newValue, 2);
                                            }}
                                            className={classes.textField}
                                            error={exceeded}
                                            helperText={exceeded && errors.total}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>{t('stepper.audienceManager.estimatedImpressions')}</Typography>
                                <Field name="impressions30Days">
                                    {({ field }) =>
                                        <TextField
                                            {...field}
                                            disabled
                                            className={classes.textField}

                                        />}
                                </Field>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default BudgetManagement;

