import React, { useState } from "react";
import { Modal, Box, Button, Typography, Grid } from "@mui/material";

// Sample images and information
const images = [
    { id: 1, src: "https://via.placeholder.com/150", info: "Image 1 info" },
    { id: 2, src: "https://via.placeholder.com/150", info: "Image 2 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
    { id: 3, src: "https://via.placeholder.com/150", info: "Image 3 info" },
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function Demos() {
    const [open, setOpen] = useState(false);
    const [payload, setPayload] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleImageClick = (imageInfo) => {
        setPayload({ data: imageInfo });
        console.log("Payload:", { data: imageInfo });
        handleClose();
    };


    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>
                Open Modal
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Select an Image
                    </Typography>
                    <Grid container spacing={2}>
                        {images.map((image) => (
                            <Grid item xs={4} key={image.id}>
                                <img
                                    src={image.src}
                                    alt={`Image ${image.id}`}
                                    style={{ width: "100%", cursor: "pointer" }}
                                    onClick={() => handleImageClick(image.info)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        style={{ marginTop: "20px" }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
