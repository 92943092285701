import React, { useState } from "react";
import { Box, Button, TextField, Typography, Breadcrumbs } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid2';
import CreateTemplateFinal from "./CreateTemplateFinal";
import CreateSmsCommunication from "./CreateSmsCommunication";
import CustomBreadcrumb from "../../components/CustomBreadcrumb/CustomBreadcrumb";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ChooseTemplate = () => {
    const [value, setValue] = useState(0);
    const { t } = useTranslation();

    const breadcrumb = [
        {
            title:t('home'),
            href:'/dashboard',
        },
        {
            title:t('templateEditor'),
            href:'/template-list',
        },
        {
            title:t('templateList.createTemplate'),
            href:'',
        }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (

        <Box sx={{ width: '100%' }}>
            <CustomBreadcrumb breadcrumb={breadcrumb}/>

            <Box sx={{ display: 'flex', marginTop: '16px' }}>
                <Typography sx={{ color: '#445A68', fontSize: '30px', fontWeight: '600' }}>{t('templateList.templateDetails')}</Typography>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth" // Makes the tabs take full width
                    sx={{
                        width: '100%',
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'space-around',
                        },
                        '.MuiTabs-indicator': {
                            height: '5px',
                            backgroundColor: '#13BECF'
                        },

                        '.MuiButtonBase-root-MuiTab-root': {
                            '.Mui-selected': {
                                color: '',
                                backgroundColor: '#000'
                            }
                        }
                    }}
                >
                    <Tab
                        sx={{
                            flex: 1,
                            '&.Mui-selected': {
                                color: '#333333',
                                fontWeight: '600',
                                backgroundColor: '#F5F5F5',
                            },
                            textTransform:'capitalize !important '

                        }}
                        label={t('templateList.Whatsapp')}
                        {...a11yProps(0)}
                    />
                    <Tab
                        sx={{

                            flex: 1,
                            '&.Mui-selected': {
                                color: '#333333',
                                fontWeight: '600',
                                backgroundColor: '#F5F5F5',
                            },
                            // textTransform:'capitalize !important '

                        }}
                        label={t('templateList.sms')}
                        {...a11yProps(1)}
                    />

                </Tabs>




            </Box>
            <CustomTabPanel value={value} index={0}>
                <CreateTemplateFinal t={t} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <CreateSmsCommunication t={t} />
            </CustomTabPanel>

        </Box>
    )
}


export default ChooseTemplate