import { Box, Button, Grid2, Typography, IconButton, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAttributesModal from "./Components/AddAttributesModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttributesMappingSlice } from "../../redux/superAdmin/superAdmin.slice";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import CustomSwitch from "../../components/CustomSwitch/CustomSwitch";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },

  selectField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
  },

  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));
const statusStyles = {
  active: {
    backgroundColor: "#e0f7e9",
    color: "#34a853",
  },
  offline: {
    backgroundColor: "#f5f5f5",
    color: "#9e9e9e",
  },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#455967",
  fontWeight: "500",
  lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#455967",
  fontWeight: "500",
}));


const AttributeTable = ({ attributeCategoryList }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { attributeMappingData, totalItems, loading } = useSelector(
    (state) => state.superAdmin
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const breadcrumb = [
    {
      title: "User Management",
      href: "",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    dispatch(fetchAttributesMappingSlice({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <Box>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: "40px",
            // padding: "15px 30px",
            color: "#445A68",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #445A68",
          }}
          onClick={() => { setOpen(true) }}
        >
          Add Attribute
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ mt: 2, border: "1px solid #D4D4D4", borderRadius: "12px" }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="campaign table">
            <TableHead sx={{ background: "#F1F1F1" }}>
              <TableRow>
                <TableCell>
                  <BoxContainer>
                    <TableHeadTitle>Attribute Name</TableHeadTitle>
                  </BoxContainer>
                </TableCell>
                <TableCell>
                  <BoxContainer>
                    <TableHeadTitle>Attribute Type </TableHeadTitle>
                  </BoxContainer>
                </TableCell>
                <TableCell>
                  <BoxContainer>
                    <TableHeadTitle>Attribute Category</TableHeadTitle>
                  </BoxContainer>
                </TableCell>
                <TableCell>
                  <BoxContainer>
                    <TableHeadTitle>Attribute MetaType</TableHeadTitle>
                  </BoxContainer>
                </TableCell>
                <TableCell>
                  <BoxContainer>
                    <TableHeadTitle>Possible Values</TableHeadTitle>
                  </BoxContainer>
                </TableCell>
                <TableCell sx={{ minWidth: "40px" }}>
                  <TableHeadTitle>Status</TableHeadTitle>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {attributeMappingData?.content?.length > 0 ? (
                attributeMappingData.content.map((item, index) => {
                  return (
                    <StyledTableRow key={index} role="checkbox">
                      <TableCell>
                        <TableDataTitle>{item?.name}</TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <TableDataTitle>{item?.type?.label}</TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <TableDataTitle>{item?.attributeCategory?.label}</TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <TableDataTitle>{item?.attributeMetaType?.label}</TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <TableDataTitle>
                          {item?.possibleValues
                            .map((val) => val.value)
                            .join(', ')}
                        </TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <CustomSwitch
                          checked={item?.active}
                          onChange={() => { }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      No Data Available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>
        )}
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Center CustomPagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <CustomPagination
            rowsPerPageOptions={[5, 10, 25]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(e) =>
              handleRowsPerPageChange(parseInt(e.target.value, 10))
            }
          />
        </Box>

        {/* Typography aligned to the end */}
        <Box
          sx={{
            flex: "0 0 auto",
          }}
        >
          <Typography sx={{
            fontSize: '19px',
            fontWeight: '600',
            background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}>
            {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
          </Typography>
        </Box>
      </Box>

      <AddAttributesModal
        open={open}
        handleClose={handleClose}
        attributeCategoryList={attributeCategoryList}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </Box>
  );
};

export default AttributeTable;
