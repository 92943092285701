import { Autocomplete, Box, Button, Card, CircularProgress, FormControlLabel, Grid2, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/CustomBreadcrumb';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { adminSignUpSlice, setFormData } from '../../../redux/superAdmin/superAdmin.slice';
import JSEncrypt from "jsencrypt";

const rsaEncrypt = (message) => {
  const publicKey =  process.env.REACT_APP_RSA_PUBLIC_KEY;
  console.log(publicKey, "publicKey")
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
  return jsEncrypt.encrypt(message);
};

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const branchOptions = [
    {
        label: 'Brand',
        value: 'BRAND'
    },
    {
        label: 'Agency',
        value: 'AGENCY'
    },
];

const categoryOptions = [
    {
        label: 'Data Provider',
        value: 'DataProvider'
    },
    {
        label: 'Data Consumer',
        value: 'DataUser'
    },
    {
        label: 'Both',
        value: 'Both'
    }
];

const RegisterPage = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading, error } = useSelector((state) => state.superAdmin);


    const breadcrumb = [
        {
            title: 'User Management',
            href: '/user-management',
        },
        {
            title: 'Register',
            href: '',
        },
    ];

    const [showPassword, setShowPassword] = useState(false);
    const [autoPassword, setAutoPassword] = useState('');

    const initialValues = {
        firstName: '',
        lastName: '',
        displayName: '',
        passwordOption: 'auto',
        customPassword: '',
        email: '',
        type: '',
        category: '',
        orgName: '',
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        displayName: Yup.string().required('Display name is required'),
        email: Yup.string().matches(
            /^[a-zA-Z0-9._%+-]+@(?!gmail\.com$|yahoo\.com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Only business emails are allowed'
        )
            .required('Email is required'),

        type: Yup.string().required('Type is required'),
        category: Yup.string().required('Category is required'),
        passwordOption: Yup.string()
            .oneOf(['auto', 'custom'], 'Invalid password option')
            .required('Password option is required'),
        customPassword: Yup.string().when('passwordOption', {
            is: 'custom',
            then: (schema) =>
                schema
                    .required('Password is required for Custom Password option')
                    .min(8, 'Password must be at least 8 characters long'),
            otherwise: (schema) => schema.notRequired(),
        }),
    })

    const generatePassword = () => {
        const chars =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$&!';
        let password = '';
        for (let i = 0; i < 12; i++) {
            password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        setAutoPassword(password);
    };

    useEffect(() => {
        generatePassword();
    }, []);

    const handleSubmit = async (values) => {
        const payload = {
            user: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.passwordOption === 'auto' ? autoPassword : values.customPassword,
                // password: values.passwordOption === 'auto' ? rsaEncrypt(autoPassword) : rsaEncrypt(values.customPassword),
                type: "ADMIN",
                groups: [values.category],
                partnerType: values.type,
                displayName: values.displayName,
                orgName: values.orgName,
                imageUrl: "https://example.com/images/john-doe.jpg",
            }
        };

        dispatch(setFormData(payload));

        try {
            const result = await dispatch(adminSignUpSlice(payload)).unwrap();
            console.log('result', result)
            if (result?.success === true) {
                navigate('/register-details');
            } else {
                console.error("Unexpected status:", result?.status);
            }
        } catch (error) {
            console.error("Error during admin signup:", error);
        }

        console.log("payload-----",payload)
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    useEffect(() => {

        formik.setFieldValue( 'displayName', `${formik.values.firstName} ${formik.values.lastName}`.trim())
      
    }, [formik.values.firstName, formik.values.lastName])

    console.log("formikRegister", formik);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <CustomBreadcrumb breadcrumb={breadcrumb} />

                <Grid2 container mt={3}>

                    <Grid2 size={{ xs: 12, md: 8 }}>
                        <Typography
                            sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
                        >
                            Register
                        </Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 4 }}>
                    </Grid2>
                </Grid2>

                <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                First Name{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Last Name{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />

                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Display Name{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="displayName"
                                value={formik.values.displayName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                                helperText={formik.touched.displayName && formik.errors.displayName}
                            />

                        </Grid2>

                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Business Email{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 12 }}>
                            <Typography className={classes.label}>
                                Choose Password{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <RadioGroup
                                row
                                name="passwordOption"
                                value={formik.values.passwordOption}
                                onChange={(event) => {
                                    formik.handleChange(event);

                                    if (event.target.value === 'auto') {
                                        generatePassword();
                                    }
                                    else {
                                        formik.setFieldValue('password', '');
                                    }
                                }}
                            >
                                <FormControlLabel
                                    value="auto"
                                    control={<Radio />}
                                    label="Auto Generate Password"
                                />
                                <FormControlLabel
                                    value="custom"
                                    control={<Radio />}
                                    label="Choose Custom Password"
                                />
                            </RadioGroup>
                            {formik.touched.passwordOption &&
                                formik.errors.passwordOption && (
                                    <Typography color="error" sx={{ mt: 1 }}>
                                        {formik.errors.passwordOption}
                                    </Typography>
                                )}
                        </Grid2>

                        {
                            formik.values.passwordOption === 'auto' && (
                                <Grid2 item size={{ xs: 12 }}>
                                    <Typography className={classes.label}>
                                        Auto Generated Password{" "}
                                        <span style={{ color: "#FF0000" }}>*</span>
                                    </Typography>

                                    <TextField
                                        value={autoPassword}
                                        type={showPassword ? 'text' : 'password'}
                                        className={classes.textField}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOffIcon />
                                                        ) : (
                                                            <VisibilityIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid2>
                            )
                        }

                        {
                            formik.values.passwordOption === 'custom' && (
                                <Grid2 item size={{ xs: 12 }}>
                                    <Typography className={classes.label}>
                                        Custom Password{" "}
                                        <span style={{ color: "#FF0000" }}>*</span>
                                    </Typography>

                                    <TextField
                                        type={showPassword ? 'text' : 'password'}
                                        name="customPassword"
                                        value={formik.values.customPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={classes.textField}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOffIcon />
                                                        ) : (
                                                            <VisibilityIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={
                                            formik.touched.customPassword &&
                                            Boolean(formik.errors.customPassword)
                                        }
                                        helperText={
                                            formik.touched.customPassword && formik.errors.customPassword
                                        } c
                                    />
                                </Grid2>
                            )
                        }

                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Type{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                name="type"
                                options={branchOptions}
                                getOptionLabel={(option) =>
                                    option?.label ? option.label : ""
                                }
                                onChange={(e, value) => {
                                    formik.setFieldValue("type", value ? value.value : "");
                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please Select Type"
                                        error={
                                            formik.touched.type && Boolean(formik.errors.type)
                                        }
                                        helperText={
                                            formik.touched.type && formik.errors.type
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Category{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>
                            <Autocomplete
                                name="category"
                                options={categoryOptions}
                                getOptionLabel={(option) =>
                                    option?.label ? option.label : ""
                                }
                                onChange={(e, value) => {
                                    formik.setFieldValue("category", value ? value.value : "");

                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please Select Category"
                                        error={
                                            formik.touched.category && Boolean(formik.errors.category)
                                        }
                                        helperText={
                                            formik.touched.category && formik.errors.category
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <Typography className={classes.label}>
                                Organisation Name{" "}
                                <span style={{ color: "#FF0000" }}>*</span>
                            </Typography>

                            <TextField
                                name="orgName"
                                value={formik.values.orgName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.orgName && Boolean(formik.errors.orgName)}
                                helperText={formik.touched.orgName && formik.errors.orgName}
                            />
                        </Grid2>
                    </Grid2>
                </Card>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                        sx={{
                            borderRadius: '10px',
                            color: '#FFFFFF !important',
                            fontSize: '18px !important',
                            background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
                            padding: '8px 16px !important',
                            textTransform: 'capitalize'
                        }}
                        type='submit'
                    >
                        Register
                    </Button>
                </Box>
            </Box>

            {
                loading ?
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    : ''
            }
        </form>
    )
}

export default RegisterPage;


