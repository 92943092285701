// import * as React from 'react';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
// import { Box } from '@mui/material';

// const CustomBreadcrumb = ({ breadcrumb }) => {

//     function handleClick(event) {
//         event.preventDefault();
//         console.info('You clicked a breadcrumb.');
//     }

//     return (
//         <Box>
//             <Breadcrumbs aria-label="breadcrumb">
//                 {
//                     breadcrumb?.map((item, index) => {

//                         const currantPage = index === breadcrumb.length - 1;

//                         return (
//                             <Link
//                                 key={index}
//                                 underline={currantPage ? 'none' : 'hover'}
//                                 color={currantPage ? 'text.primary' : 'inherit'}
//                                 href={currantPage ? undefined : item.href}
//                                 aria-current={currantPage ? 'page' : undefined}

//                             >
//                                 {item?.title}
//                             </Link>
//                         )
//                     })
//                 }
//             </Breadcrumbs>
//         </Box>
//     )
// }

// export default CustomBreadcrumb


import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const CustomBreadcrumb = ({ breadcrumb }) => {
    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumb?.map((item, index) => {
                    const currentPage = index === breadcrumb.length - 1;

                    return currentPage ? (
                        <Typography
                            key={index}
                            color="text.primary"
                            aria-current="page"
                        >
                            {item?.title}
                        </Typography>
                    ) : (
                        <Link
                            key={index}
                            to={item.href}
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                                transition: "text-decoration 0.2s",
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
                            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
                        >
                            {item?.title}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};

export default CustomBreadcrumb;


