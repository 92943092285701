import { Box, Button, FormHelperText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListContentModal from "../Components/ListContentModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  fetchCreateCampaignSlice,
  fetchSMSTemplateSlice,
  fetchTemplateListDataSlice,
  fetchWhatsAppTemplateSlice,
} from "../../../redux/stepperSlice/stepper.slice";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import TemplateDetailsModal from "../Components/TemplateDetailsModal";
import TemplateViewScreen from "../Components/TemplateViewScreen";

const smsData = {
  "id": "67441c89ff0d4f7233c331b3",
  "orgId": "66ffacd73030fd2b301f0c07",
  "templateId": "template-act-12",
  "templateCategory": "MARKETING",
  "templateText": "This is a sample template text-act-12.",
  "templateName": "my-temp-act-12",
  "status": "APPROVED",
  "media": null,
  "templateType": "SMS",
  "categoryId": "id-act-12",
  "createdOn": null,
  "createdBy": "Mukti",
  "updatedOn": null,
  "updatedBy": null,
  "deleted": false
}

const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};


const channelData = [
  {
    label: "SMS",
    value: "SMS",
  },
  {
    label: "WhatsApp",
    value: "WHATSAPP",
  },
];

const CampaignDetails = ({
  formValues,
  classes,
  handleNext,
  setCampaignDetails,
  setFormValues,
  setCampaignId,
  previewMedia,
  setPreviewMedia,
  t,
}) => {
  const initialValues = formValues;
  const [open, setOpen] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [contentIds, setContentIds] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [templateDetails, setTemplateDetails] = useState([]);
  const [templateData, setTemplateData] = useState('');
  const [medidaURL, setMedidaURL] = useState(null);
  const [medidaType, setMediType] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();


  const validationSchema = Yup.object({
    title: Yup.string().required(t('validate.campaignTitleReq')),
    description: Yup.string().required(t('validate.campaignDescriptionReq')),
    channel: Yup.string().required(t('validate.channelReq')),

    totalBudget: Yup.string().required(t('validate.totalBudgetReq')),
    startDateTime: Yup.date()
      .min(new Date(), "Start date and time cannot be in the past")
      .required("Start date and time is required"),
    endDateTime: Yup.date()
      .min(
        Yup.ref("startDateTime"),
        "End date and time cannot be before start date and time"
      )
      .test(
        "max-37-days",
        "End date and time cannot be more than 37 days after start date and time",
        function (value) {
          const startDate = this.parent.startDateTime;
          if (startDate && value) {
            const maxEndDate = new Date(startDate);
            maxEndDate.setDate(maxEndDate.getDate() + 37);
            return value <= maxEndDate;
          }
          return true;
        }
      )
      .required("End date and time is required"),
    media: Yup.string().required(t('validate.selectMediaContent')),

  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTemplateOpen = () => {
    if (formik.values.channel == "") {
      toast.error(t('validate.selectChannelFirst'));
      return;
    }

    setTemplateOpen(true);
  };

  const handleTemplateClose = () => {
    setTemplateOpen(false);
  };

  const handleTemplateList = async (e, value) => {
    if (!value) {
      formik.setFieldValue("channel", "");
      setTemplateDetails([]);
      formik.setFieldValue("templateDetails", "");
      return;
    }

    formik.setFieldValue("channel", value ? value.value : "");
    formik.setFieldValue("templateDetails", "");
    setTemplateId("");

    const payload = {
      data: value.value,
      page: page,
      rowsPerPage: rowsPerPage
    }

    const result = await dispatch(
      fetchTemplateListDataSlice(payload)
    ).unwrap();

    if (result) {
      console.log("temp result", result);
      setTemplateDetails(result);
    } else {
    }
  };

  const handleSubmit = async (values) => {

    const payload = {
      channel: values.channel,
      campaignName: values.title,
      description: values.description,
      templateId: templateId?.templateId,
      goalDescription: "dummy Data",
      startDate: values.startDateTime ? formatDate(values.startDateTime) : null,
      endDate: values.endDateTime ? formatDate(values.endDateTime) : null,
      scheduled: true,
      scenario: "Nothing",
      contentIds: [contentIds[0]?.id],
      budget: values.totalBudget,
      approxAudience: 133,
      campaignType: formValues?.campaignType,
      startDateEpoch: values.startDateTime
        ? new Date(values.startDateTime).getTime()
        : null,
      endDateEpoch: values.endDateTime
        ? new Date(values.endDateTime).getTime()
        : null,
    };

    setFormValues(values);

    const updatedValues = {
      ...values,
      templateViewData: templateData,
    };

    setFormValues(updatedValues);

    const result = await dispatch(fetchCreateCampaignSlice(payload)).unwrap();

    if (result) {
      setCampaignId(result.campaignId);
      toast.success(t('campaignDetailsSubmitted'));
      handleNext();
    } else {
      console.log("Error");
    }

    console.log("Form Submission---", payload)
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleDraft = () => {
    setCampaignDetails(false);
  };

  const formik = useFormik({
    initialValues: {
      ...formValues,
    },

    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {

    const fetchTemplateDetails = async () => {
      if (!templateId) {
        return;
      }

      const tempType = formik.values.channel;

      if (tempType === "SMS") {

        const result = await dispatch(fetchSMSTemplateSlice(templateId.id)).unwrap();

        if (result) {
          setTemplateData(result);
        }
        else {
          console.log("error");
        }
      }
      else {
        const result = await dispatch(fetchWhatsAppTemplateSlice(templateId.templateId)).unwrap();

        if (result) {
          setTemplateData(result);
        }
        else {
          console.log("error");
        }
      }
    }

    fetchTemplateDetails();

  }, [templateId, dispatch])



  useEffect(() => {
    formik.setFieldValue("media", contentIds.join(", "));
    formik.setFieldValue("mediaImage", medidaURL);
    formik.setFieldValue("mediaType", medidaType);

    setFormValues(formik.values);
  }, [contentIds, formik.values, setFormValues]);

  console.log("formik---Camp", formik);

  console.log("tempId-----",templateId);

  console.log("templateData-----",templateData);

  return (
    <form onSubmit={formik.handleSubmit} style={{ marginTop: "20px" }}>
      <Accordion
        sx={{
          border: "1px solid #ccc",
          borderRadius: "10px !important",
          boxShadow: "none",
          overflow: "hidden",
          boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
          marginBottom: "25px !important",
        }}
        defaultExpanded
      >
        <AccordionSummary
          sx={{
            minHeight: "65px ",
            color: "#333333",
            fontWeight: "500",
            fontSize: "19px",
            backgroundColor: "#F9FAFB",
            borderTopLeftRadius: "15px !important",
            borderTopRightRadius: "15px !important",
            borderRadius: "10px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <RadioButtonUncheckedIcon
              sx={{
                fill: "#13BECF",
                borderRadius: "50%",
                width: "25px",
                height: "25px",
              }}
            />
            {t("stepper.campaignDetails.campaignDetails")}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={5} paddingY={2} paddingX={1}>
            <Grid item container spacing={2} size={7}>
              <Grid size={12}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.campaignTitle")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>

                <TextField
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={classes.textField}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>

              <Grid size={12}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.brandNameOrWebsiteUrl")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>

                <TextField
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  className={classes.textField}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>

              <Grid size={{ md: 12 }}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.channel")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>
                <Autocomplete
                  // disablePortal
                  name="channel"
                  options={channelData}
                  getOptionLabel={(option) =>
                    option?.label ? option.label : ""
                  }
                  onChange={(e, value) => {
                    handleTemplateList(e, value);
                  }}
                  onBlur={formik.handleBlur}
                  className={classes.selectField}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Template Channel"
                      error={
                        formik.touched.channel && Boolean(formik.errors.channel)
                      }
                      helperText={
                        formik.touched.channel && formik.errors.channel
                      }
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>

              <Grid size={12}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.templateDetails")}
                </Typography>
                <TextField
                  fullWidth
                  // disabled
                  name="templateDetails"
                  value={formik.values.templateDetails}
                  variant="outlined"
                  className={classes.textField}
                  error={
                    formik.touched.templateDetails &&
                    Boolean(formik.errors.templateDetails)
                  }
                  helperText={
                    formik.touched.templateDetails &&
                    formik.errors.templateDetails
                  }
                  onClick={handleTemplateOpen}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Typography
                          sx={{
                            color: "#445A68",
                            cursor: "pointer",
                            marginRight: "8px",
                          }}
                        onClick={handleTemplateOpen}
                        >
                          {t("stepper.campaignDetails.browse")}
                        </Typography>
                      </>
                    ),
                  }}
                />
              </Grid>

              <Grid size={12}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.totalBudget")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>

                <TextField
                  name="totalBudget"
                  value={formik.values.totalBudget}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={classes.textField}
                  error={
                    formik.touched.totalBudget &&
                    Boolean(formik.errors.totalBudget)
                  }
                  helperText={
                    formik.touched.totalBudget && formik.errors.totalBudget
                  }
                />
              </Grid>

              <Grid size={12}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.media")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>

                <TextField
                  fullWidth
                  // disabled
                  name="media"
                  value={contentIds[0]?.templateName || ""}
                  variant="outlined"
                  className={classes.textField}
                  error={formik.touched.media && Boolean(formik.errors.media)}
                  helperText={formik.touched.media && formik.errors.media}
                  onClick={handleOpen}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Typography
                          sx={{
                            color: "#445A68",
                            cursor: "pointer",
                            marginRight: "8px",
                          }}
                          onClick={handleOpen}
                        >
                          {t("stepper.campaignDetails.browse")}
                        </Typography>
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid size={5} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>

              {(formik.values.channel === 'SMS' || formik.values.channel === 'WHATSAPP') && templateId && (
                <TemplateViewScreen
                  tempType={formik.values.channel}
                  templateData={templateData}
                />
              )}

            </Grid>
          </Grid>
          <Grid container spacing={3} marginTop={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography className={classes.label}>
                {t("stepper.campaignDetails.startDate")}:{" "}
                <span style={{ color: "#FF0000" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  name="startDateTime"
                  value={formik.values.startDateTime}
                  onChange={(value) => formik.setFieldValue("startDateTime", value)}
                  onBlur={formik.handleBlur}
                  className={classes.textField}
                  disablePast
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={formik.touched.startDateTime && Boolean(formik.errors.startDateTime)}
                      helperText={formik.touched.startDateTime && formik.errors.startDateTime}
                      className={classes.textField}
                    />
                  )}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {console.log("errorrr---start", formik.touched)}
                {formik.touched.startDateTime && formik.errors.startDateTime}
              </FormHelperText>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Typography className={classes.label}>
                {t("stepper.campaignDetails.endDate")}:{" "}
                <span style={{ color: "#FF0000" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  name="endDateTime"
                  disablePast
                  value={formik.values.endDateTime}
                  className={classes.textField}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("endDateTime", value)}
                  minDateTime={formik.values.startDateTime}
                  maxDateTime={
                    formik.values.startDateTime
                      ? new Date(new Date(formik.values.startDateTime).setDate(new Date(formik.values.startDateTime).getDate() + 37))
                      : undefined
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={formik.touched.endDateTime && Boolean(formik.errors.endDateTime)}
                      helperText={formik.touched.endDateTime && formik.errors.endDateTime}
                      className={classes.textField}
                    />
                  )}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {console.log("errorrr---", formik.touched.endDateTime)}
                {formik.touched.endDateTime && formik.errors.endDateTime}
              </FormHelperText>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          paddingY: "10px",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          sx={{
            borderRadius: "40px",
            padding: "16px 24px",
            fontSize: "18px",
            backgroundColor: "#445A68",
            color: "#FFFFFF",
            fontWeight: "600",
            border: "1px solid rgba(68, 90, 104, 1)",
          }}
          onClick={handleDraft}
        >
          {t("stepper.saveForDraft")}
        </Button>
        <Button
          variant="outlined"
          type="submit"
          endIcon={<ArrowForwardIcon />}
          sx={{
            borderRadius: "40px",
            padding: "16px 24px",
            color: "#FFFFFF",
            background: "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #13BECF",
          }}
        >
          {t("stepper.saveAndNext")}
        </Button>
      </Box>

      <ListContentModal
        open={open}
        handleClose={handleClose}
        setContentIds={setContentIds}
        setMedidaURL={setMedidaURL}
        setMediType={setMediType}
        classes={classes}
        setPreviewMedia={setPreviewMedia}
        t={t}
      />
      <TemplateDetailsModal
        open={templateOpen}
        handleClose={handleTemplateClose}
        classes={classes}
        templateId={templateId}
        setTemplateId={setTemplateId}
        templateDetails={templateDetails}
        formik={formik}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        t={t}
      />
    </form>
  );
};

export default CampaignDetails;