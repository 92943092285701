import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  TablePagination,
  Card,
  Typography,
  Breadcrumbs,
  Grid2,
  Chip,
  Button,
  Autocomplete
} from "@mui/material";
import { AddIcCallRounded, Search } from "@mui/icons-material";
import { fetchContentListSlice } from "../../redux/stepperSlice/stepper.slice";
import CustomModal from "../../components/CustomModal/CustomModal";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ContactsIcon from "@mui/icons-material/Contacts";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MmsIcon from "@mui/icons-material/Mms";
import DateRangeIcon from '@mui/icons-material/DateRange';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import BadgeIcon from '@mui/icons-material/Badge';
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";
import { fetchTemplateListSlice, searchTemplateListSlice, templateDeleteSlice, templateListSearchSlice } from "../../redux/teamSlice/team.slice";
import CustomBreadcrumb from "../../components/CustomBreadcrumb/CustomBreadcrumb";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },

  selectField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
  },


  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));
const statusStyles = {
  active: {
    backgroundColor: "#e0f7e9",
    color: "#34a853",
  },
  offline: {
    backgroundColor: "#f5f5f5",
    color: "#9e9e9e",
  },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#455967",
  fontWeight: "500",
  lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#455967",
  fontWeight: "500",
}));


function formatDate(dateString) {
  if (!dateString) {
    return false
  }
  const date = new Date(dateString);

  // Options for formatting
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    //   hour: "numeric",
    //   minute: "numeric",
    //   hour12: true, // Use 12-hour format
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

const templateOptions = [
  {
    label: 'MARKETING',
    value: 'MARKETING',
  },
  {
    label: 'UTILITY',
    value: 'UTILITY',
  },
  {
    label: 'AUTHENTICATION',
    value: 'AUTHENTICATION',
  }
]


const Templatelist = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const breadcrumb = [
    {
      title: t('home'),
      href: '/dashboard',
    },
    {
      title: t('templateEditor'),
      href: '',
    }
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { templateList, totalItems, loading } = useSelector(
    (state) => state.teamsDetails
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [templateValue, setTemplateValue] = useState(null);
  const [selected, setSelected] = useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Function to handle row selection
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDelete = (item) => {
    console.log("row---", item.id);

    const result = dispatch(templateDeleteSlice(item.id)).unwrap();

    if (result) {
      dispatch(fetchTemplateListSlice({ page, rowsPerPage }));
    }

  }

  // useEffect(() => {
  //   dispatch(fetchContentListSlice({ page, rowsPerPage }));
  // }, [dispatch, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleTemplateCategory = (event, newValue) => {

    setTemplateValue(newValue)

    // var data = {
    //   templateCategory: newValue,
    //   page: page,
    // }

    var payload = {
      data: [
        {
          templateCategory: newValue?.value ? newValue?.value : '',
        },
        {
          templateName: searchQuery ? searchQuery : '',
        }
      ],
      page: page || 0,
      size: rowsPerPage || 10
    }

    dispatch(searchTemplateListSlice(payload))
  }

  const handleSearchChange = (event) => {

    console.log('adadad', event.target.value)


    // var data = {
    //   templateCategory: templateValue,
    //   page: page,
    //   value: event.target.value
    // }

    var payload = {
      data: [
        {
          templateCategory: templateValue?.value ? templateValue?.value : '',
        },
        {
          templateName: event.target.value ? event.target.value : ''
        }
      ],
      page: page || 0,
      size: rowsPerPage || 10
    }

    setSearchQuery(event.target.value);
    dispatch(searchTemplateListSlice(payload))

    // const result = await dispatch(templateListSearchSlice(searchQuery)).unwrap();
  };

  useEffect(() => {
    dispatch(fetchTemplateListSlice({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);



  return (
    <Box>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      <Grid2 container sx={{ justifyContent: "end" }} mt={3} spacing={2}>

        <Grid2 size={{ xs: 12, md: 6 }}>
          <Typography
            sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
          >
            {t('templateList.templateList')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Autocomplete
            options={templateOptions}
            getOptionLabel={(option) => option.label}
            className={classes.selectField}
            value={templateValue}
            onChange={(event, newValue) => handleTemplateCategory(event, newValue)}
            renderInput={(params) =>
              <TextField
                {...params}
                className={classes.searchBar}
                placeholder={t('templateList.searchByTempCategory')}
              />}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <TextField
            variant="outlined"
            placeholder={t('templateList.searchByName')}
            fullWidth
            className={classes.searchBar}
            sx={{ color: "#ECECEC" }}
            value={searchQuery}
            onChange={e => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
      </Grid2>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: "40px",
            // padding: "15px 30px",
            color: "#445A68",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #445A68",
          }}
          onClick={() => { navigate('/create-template') }}
        >
          {t('templateList.createNewTemplate')}
        </Button>
      </Box>
      
      <TableContainer
        component={Paper}
        sx={{ mt: 3, border: "1px solid #D4D4D4", borderRadius: "12px" }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="campaign table">
            <TableHead sx={{ background: '#F1F1F1' }}>
              <TableRow>
                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <MmsIcon fontSize={"14px"} sx={{ color: "#455967" }} />
                    <TableHeadTitle>{t('templateList.templateName')}</TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>

                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <LocationOnIcon
                      fontSize={"14px"}
                      sx={{ color: "#455967" }}
                    />
                    <TableHeadTitle>{t('templateList.templateCategory')}</TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>

                {/* <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <BadgeIcon
                      fontSize={"14px"}
                      sx={{ color: "#455967" }}
                    />
                    <TableHeadTitle>Template Id</TableHeadTitle>
                  </BoxContainer>
                </TableCell> */}
                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <DateRangeIcon fontSize={"14px"} sx={{ color: "#455967" }} />
                    <TableHeadTitle>{t('templateList.templateType')} </TableHeadTitle>
                  </BoxContainer>
                </TableCell>

                <TableCell sx={{ minWidth: '170px' }}>
                  <BoxContainer>
                    <NewReleasesIcon
                      fontSize={"14px"}
                      sx={{ color: "#455967" }}
                    />
                    <TableHeadTitle>{t('templateList.status')} </TableHeadTitle>
                    {/* <SwapVertIcon fontSize={"14px"} sx={{ color: "#455967" }} /> */}
                  </BoxContainer>
                </TableCell>

                <TableCell sx={{ minWidth: '100px' }}>
                  {/* <BoxContainer>
                                        <TableHeadTitle>Actions</TableHeadTitle>
                                        <SwapVertIcon fontSize={'14px'} sx={{ color: "#455967" }} />
                                    </BoxContainer> */}
                </TableCell>


              </TableRow>
            </TableHead>

            <TableBody>

              {templateList?.content?.length ? templateList?.content?.map((item, key) => {

                const isItemSelected = isSelected(item.id);
                const labelId = `campaign-table-checkbox-${item.id}`;
                return (
                  <StyledTableRow
                    onClick={(event) => handleClick(event, item.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={item.id}
                    selected={isItemSelected}
                  >
                    <TableCell>
                      <TableDataTitle>{item?.templateName ? item?.templateName : 'Name not persent'}</TableDataTitle>
                    </TableCell>
                    <TableCell>
                      <TableDataTitle>{item?.templateCategory ? item?.templateCategory : 'Category not persent'}</TableDataTitle>
                    </TableCell>
                    {/* <TableCell>
                      <TableDataTitle>{item?.id}</TableDataTitle>
                    </TableCell> */}
                    <TableCell>
                      <TableDataTitle>{item?.templateType === 'WHATSAPP' ? 'Whatsapp' : item?.templateType}</TableDataTitle>
                    </TableCell>

                    <TableCell>
                      <Chip
                        label={`• ${item.status === "APPROVED" ? "Approved" : "Rejected"}`}
                        sx={{
                          ...statusStyles[
                          item.status === 'APPROVED' ? "active" : "offline"
                          ],
                          fontWeight: "bold",
                          fontSize: "14px",
                          padding: "2px 6px",
                          borderRadius: "2px",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton disabled={true} color="#455967">
                        <EditIcon />
                      </IconButton>
                      <IconButton color="#455967" onClick={() => handleDelete(item)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              }) : (
                <StyledTableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="h6" color="textSecondary">
                      No data found
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <CustomPagination
            rowsPerPageOptions={[5, 10, 25]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(e) =>
              handleRowsPerPageChange(parseInt(e.target.value, 10))
            }
          />
        </Box>
        <Box sx={{ flex: '0 0  auto' }}>
          <Typography sx={{
            fontSize: '19px',
            fontWeight: '600',
            background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}>
            {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Templatelist;
